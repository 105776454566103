import { useEffect, useCallback, useMemo } from 'react'
import { useEthers } from '@usedapp/core'
import { formatEther } from 'ethers/lib/utils'
import { Contract, BigNumber } from 'ethers'
import useSWR from 'swr'

import { useProvider } from './useProvider'
import useNFTContract from './useNFTContract'
import { contracts } from 'config/contracts'
import { ERC20Abi, ReferralHandlerAbi } from 'config/abis'
import { contractFetcher } from 'utils/contracts/contractFetcher'
import { callContract } from 'utils/contracts/callContract'

const useReferralRewards = (mutate: boolean = false) => {
	const library = useProvider()
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'
	const { referralHandler } = useNFTContract()

	const { data: referralHandlerBalance, mutate: referralHandlerBalanceMutate } = useSWR(
		['ETF.balanceOf', contracts.ETF, 'balanceOf', referralHandler],
		{
			fetcher: contractFetcher(library, ERC20Abi),
		}
	)

	const { data: remainingClaims, mutate: remainingClaimsMutate } = useSWR(
		['ReferralHandler.remainingClaims', referralHandler, 'remainingClaims'],
		{
			fetcher: contractFetcher(library, ReferralHandlerAbi),
		}
	)

	const referralHandlerContract = useMemo(
		() =>
			referralHandler
				? new Contract(referralHandler, ReferralHandlerAbi, library.getSigner())
				: undefined,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[library, referralHandler]
	)

	const claimReferralReward = useCallback(
		async () => {
			const opts = {}
			return referralHandlerContract
				? await callContract(referralHandlerContract, 'claimReward', [], opts)
				: undefined
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[referralHandlerContract, account]
	)

	useEffect(() => {
		referralHandlerBalanceMutate(undefined, true)
		remainingClaimsMutate(undefined, true)
	}, [mutate, referralHandlerBalanceMutate, remainingClaimsMutate])

	return {
		remainingClaims: (remainingClaims as BigNumber)?.toNumber(),
		referralHandlerBalance: referralHandlerBalance
			? parseFloat(formatEther(referralHandlerBalance))
			: undefined,
		claimReferralReward,
	}
}

export default useReferralRewards
