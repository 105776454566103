import { useEffect, useState } from 'react'
import Helmet from 'react-helmet'

import Header from './Header'
import Sidebar from './Sidebar'
import DisclaimerModal from 'components/Modal/Disclaimer'

interface Props {
	children?: React.ReactNode
	title: string
}

const Layout = (props: Props) => {
	const { children, title } = props
	const [isOpen, setIsOpen] = useState<boolean>(false)

	useEffect(() => {
		const date = new Date()
		if (date.toDateString() !== localStorage.getItem('today')) setIsOpen(true)
	}, [])

	return (
		<>
			<DisclaimerModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<div className="flex overflow-x-hidden bg-[url('/public/images/background-2.png')] bg-cover">
				<Sidebar />
				<div className="flex h-screen w-full flex-col">
					<Header />
					<main className="scrollbar-hide grow overflow-y-scroll">{children}</main>
				</div>
			</div>
		</>
	)
}

export default Layout
