import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import ModalWrapper from 'components/Wrapper/ModalWrapper'
import ContentWrapper from 'components/Wrapper/ContentWrapper'
import { ReactComponent as CloseIcon } from '@material-icons/svg/svg/close/outline.svg'
import { pairTokens } from 'config/pairTokens'

interface Props {
	isOpen: boolean
	closeModal: () => void
	onSelect: (_unit: PairToken) => void
}

const SelectTokenModal = (props: Props) => {
	const { isOpen, closeModal, onSelect } = props
	const { t } = useTranslation()

	const handleCoinSelect = (unit: PairToken) => {
		onSelect(unit)
		closeModal()
	}

	return (
		<Modal ariaHideApp={false} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={closeModal}>
			<ModalWrapper className="!w-[440px] sm:!w-[330px]">
				<CloseIcon
					className="absolute top-8 right-6 h-6 w-6 cursor-pointer fill-primary sm:top-3 sm:right-3"
					onClick={closeModal}
				/>
				<h1 className="text-lg text-primary">{t('Select a token')}</h1>
				<div className="mt-8 grid grid-cols-2 gap-x-[60px] gap-y-10 sm:gap-x-8 sm:gap-y-6">
					{pairTokens.map((coin, index) => (
						<ContentWrapper
							className="cursor-pointer !rounded-[10px] px-6 py-3 sm:px-3"
							key={index}
							onClick={() => handleCoinSelect(coin)}
						>
							<div className="flex items-center gap-2.5">
								<img src={`/images/coin/${coin.unit}.png`} alt="" className="h-10 w-10" />
								<span className="text-[15px] font-bold text-primary">{coin.name}</span>
							</div>
						</ContentWrapper>
					))}
				</div>
			</ModalWrapper>
		</Modal>
	)
}

export default SelectTokenModal
