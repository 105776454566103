import { useEffect, useState, useRef, useCallback, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import ModalWrapper from 'components/Wrapper/ModalWrapper'
import ContentWrapper from 'components/Wrapper/ContentWrapper'
import { ReactComponent as CloseIcon } from '@material-icons/svg/svg/close/outline.svg'
import { amountFormatter } from 'utils/formatter'

interface Props {
	type: 'stake' | 'unstake'
	name: string
	unit: string
	isOpen: boolean
	availableBalance: number | undefined
	unlocksAt: number | undefined
	setIsOpen: (value: boolean) => void
	allowance: () => Promise<number | undefined>
	onStake: (amount: number, period: number) => void
	onApprove: () => Promise<void>
	onUnstake: (amount: number) => void
}

const StakeUnstakeModal = (props: Props) => {
	const timerRef = useRef<NodeJS.Timer>()
	const allowanceRef = useRef<NodeJS.Timeout>()
	const { t } = useTranslation()

	const {
		type,
		name,
		unit,
		isOpen,
		availableBalance,
		unlocksAt,
		setIsOpen,
		allowance,
		onApprove,
		onStake,
		onUnstake,
	} = props

	const [days, setDays] = useState<number>(14)
	const [amount, setAmount] = useState<number>(0)
	const [duration, setDuration] = useState<string>('00:00:00')
	const [isApproved, setIsApproved] = useState<boolean>(false)

	const updateTime = useCallback(() => {
		if (!unlocksAt) {
			return
		}

		const timestamp = Math.floor(new Date().getTime() / 1000)
		const delta = unlocksAt - timestamp
		if (delta < 0) {
			return
		}

		const newDurationString =
			Math.floor(delta / 3600)
				.toString()
				.padStart(2, '0') +
			':' +
			Math.floor((delta / 60) % 60)
				.toString()
				.padStart(2, '0') +
			':' +
			Math.floor(delta % 60)
				.toString()
				.padStart(2, '0')

		setDays(Math.floor((delta / 24 - 1) / 3600) + 1)
		setDuration(newDurationString)
	}, [unlocksAt])

	useEffect(() => {
		if (type === 'unstake' || unlocksAt) {
			setDays(0)
			timerRef.current = setInterval(updateTime, 1000)
		}

		return () => clearInterval(timerRef.current)
	}, [type, unlocksAt, updateTime])

	useEffect(() => {
		if (availableBalance === undefined) {
			return
		}

		if (amount > availableBalance) {
			setAmount(availableBalance)
		}
		setIsApproved(false)
	}, [amount, availableBalance])

	const updateAllowance = useCallback(
		async (
			allowance: () => Promise<number | undefined>,
			approved: boolean,
			amount: number,
			setApproved: (value: SetStateAction<boolean>) => void
		) => {
			const allowanceBalance = (await allowance()) ?? 0
			if (allowanceBalance > amount) {
				if (!approved) {
					setApproved(true)
				}
			} else {
				if (approved) {
					setApproved(false)
				}
			}
		},
		[]
	)

	useEffect(() => {
		if (allowanceRef.current) {
			clearTimeout(allowanceRef.current)
		}
		allowanceRef.current = setTimeout(async () => {
			await updateAllowance(allowance, isApproved, amount, setIsApproved)
			allowanceRef.current = undefined
		}, 500)
	}, [amount, updateAllowance, allowance, isApproved, isOpen])

	const handleStake = () => {
		setIsOpen(false)
		onStake(amount, days)
	}

	const handleApprove = async () => {
		setIsOpen(false)
		await onApprove()
		setIsOpen(true)
	}

	const handleUnstake = () => {
		setIsOpen(false)
		onUnstake(amount)
	}

	return (
		<Modal
			ariaHideApp={false}
			closeTimeoutMS={200}
			isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
		>
			<ModalWrapper className="text-primary">
				<CloseIcon
					className="absolute top-8 right-6 h-8 w-8 cursor-pointer fill-primary sm:top-3 sm:right-3 sm:h-6 sm:w-6"
					onClick={() => setIsOpen(false)}
				/>
				<div className="mx-auto flex flex-col items-center">
					<div className="flex items-end">
						<img src={`/images/coin/${unit}.png`} alt="" className="z-10 -mr-6 h-24 w-24" />
						<img src="/images/quota-coin.png" alt="" className="h-20 w-20" />
					</div>
					<p className="mt-3 text-2xl font-bold text-pink">{name} - 4.0</p>
				</div>
				<div className="mt-16 flex items-center gap-2.5 sm:mt-6 sm:flex-col sm:items-start sm:gap-8">
					<span className="">{t('Amount')}</span>
					<div className="flex w-full items-center gap-2.5">
						<ContentWrapper className="relative w-full sm:rounded-md">
							<input
								type="number"
								className="w-full bg-transparent py-3 px-4 text-right sm:text-xs"
								value={amount}
								onChange={(e) => {
									setAmount(parseFloat(e.target.value))
								}}
							/>
							<p className="absolute -top-6 right-0 text-xs sm:-top-5 sm:text-[10px]">
								<span className="text-gray">{t('LP Balance Available')}: </span>
								{availableBalance !== undefined ? amountFormatter.format(availableBalance) : '-'}
							</p>
						</ContentWrapper>
						<button
							className="h-12 rounded-[10px] bg-gray px-5 text-xl text-orange shadow-[3px_3px_6px_#00000043] sm:h-9 sm:px-3 sm:text-[15px]"
							onClick={() => setAmount(availableBalance ?? 0)}
						>
							{t('MAX')}
						</button>
					</div>
				</div>
				{type === 'stake' ? (
					<div className="mt-6 flex w-full flex-col items-center">
						<p className="text-2xl font-bold">{days} Days</p>
						<div className="flex w-full items-center gap-3">
							<p className="whitespace-nowrap sm:hidden">{t('Lock Duration')}</p>
							<Slider
								step={1}
								min={0}
								max={30}
								onChange={(value: number) => setDays(value)}
								value={days}
								className="w-full bg-orange"
							/>
							<p className="sm:hidden">{t('Days')}</p>
						</div>
					</div>
				) : (
					<div className="mt-8 ml-[72px] sm:ml-0">
						<p className="sm:text-sm">{t('Remaining Lock Duration')}</p>
						<div className="mt-2 flex gap-12 sm:mt-5 sm:gap-5">
							<div className="sm:w-full">
								<p className="text-xs">{t('Days')}</p>
								<ContentWrapper className="mt-1 w-32 px-5 py-1.5 sm:w-full sm:rounded-md sm:px-2">
									<p className="text-right text-3xl font-bold text-orange sm:text-sm">{days}</p>
								</ContentWrapper>
							</div>
							<div className="sm:w-full">
								<p className="text-xs">{t('Time')}</p>
								<ContentWrapper className="mt-1 w-64 px-5 py-1.5 sm:w-full sm:rounded-md sm:px-2">
									<p className="text-right text-3xl font-bold text-orange sm:text-sm">{duration}</p>
								</ContentWrapper>
							</div>
						</div>
						{days > 0 ? (
							<p className="mt-3.5 text-xs text-red">
								{t('You still have “Remaining Lock Duration”')}
							</p>
						) : (
							<></>
						)}
					</div>
				)}
				<button
					className="btn mx-auto mt-12 first-letter:uppercase"
					disabled={(days > 0 && !isApproved && !amount) || (type === 'unstake' && days > 0)}
					onClick={() =>
						type === 'stake' ? (isApproved ? handleStake() : handleApprove()) : handleUnstake()
					}
				>
					{type === 'stake' && !isApproved ? t('Approve') : t(type)}
				</button>
			</ModalWrapper>
		</Modal>
	)
}

export default StakeUnstakeModal
