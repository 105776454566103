import {useContext, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { shortenAddress, useEthers } from '@usedapp/core'

import NFTDetailModal from 'components/Modal/NFTDetail'
import { tiers } from 'config/tiers'
import {CommonContext} from "../../contexts/common";

const Gallery = () => {
	const [tier, setTier] = useState<string>('All')
	// const [mutate, setMutate] = useState<boolean>(false)
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [selectedNFT, setSelectedNFt] = useState<NFTNode>()
	const { t } = useTranslation()
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'
	const { allNodes } = useContext(CommonContext)

	const handleClick = (nft: any) => {
		setSelectedNFt(nft)
		setIsOpen(true)
	}

	return (
		<div className="flex w-full flex-col items-center text-primary">
			{account ? (
				<>
					<NFTDetailModal isOpen={isOpen} closeModal={() => setIsOpen(false)} data={selectedNFT} />
					<div className="flex flex-wrap justify-center gap-8 text-gray xl:gap-4 sm:gap-y-2">
						{tiers.map((_tier, index) => (
							<div
								className="h-8 w-24 cursor-pointer hover:opacity-80 sm:w-20"
								key={index}
								onClick={() => setTier(_tier)}
							>
								<p className="text-center text-[15px] uppercase">{t(_tier)}</p>
								{tier === _tier && (
									<div className="h-1.5 w-full rounded-full from-maroon to-orange shadow-[1px_1px_3px_#0000001A] bg-gradient-279" />
								)}
							</div>
						))}
					</div>
					<div className="mt-16 grid w-full grid-cols-5 gap-y-16 lg:mt-10 lg:grid-cols-4 sm:mt-4 sm:grid-cols-3 sm:gap-y-4">
						{allNodes.map((nft, index) => (
							<div
								className={`${
									nft.tier === tiers.length - 1 - tiers.indexOf(tier) || tier === 'All'
										? 'flex'
										: 'hidden'
								} flex-col items-center`}
								key={index}
								onClick={() => handleClick(nft)}
							>
								<img
									src={`/images/nfts/${tiers[tiers.length - 1 - nft.tier].toLowerCase()}.png`}
									alt="/"
									className="w-full max-w-[139px] sm:max-w-[64px]"
								/>
								<p className="mt-3 text-lg sm:mt-1 sm:text-[10px]">
									{shortenAddress(nft.ownerAddress)}
								</p>
							</div>
						))}
					</div>
				</>
			) : (
				<div className="mt-12 flex flex-col items-center gap-12 md:gap-6">
					<div className="flex items-end">
						<img src="/images/nfts/silver.png" alt="" className="h-fit w-44 md:w-20" />
						<img
							src="/images/nfts/black.png"
							alt=""
							className="relative z-10 -mx-16 mb-10 h-fit w-56 md:-mx-6 md:mb-6 md:w-[104px]"
						/>
						<img src="/images/nfts/gold.png" alt="" className="h-fit w-44 md:w-20" />
					</div>
					<p className="text-4xl md:text-base">{t('Connect your wallet & Mint your NFT!')}</p>
				</div>
			)}
		</div>
	)
}

export default Gallery
