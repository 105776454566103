import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContentWrapper from 'components/Wrapper/ContentWrapper'
import StakeUnstakeModal from 'components/Modal/StakeUnstake'
import SignTransactionModal from 'components/Modal/SignTransaction'
import LoadingModal from 'components/Modal/Loading'
import { ReactComponent as LockIcon } from 'icons/lock.svg'

import useStakingPool from 'hooks/useStakingPool'
import { perpetualStakingPools } from 'config/perpetualStakingPools'
import { toast } from 'react-toastify'
import { amountFormatter } from 'utils/formatter'
interface TokenComponentProps {
	token: {
		name: string
		unit: string
		pairAddress: string
		stakingAddress: string
		multi: number
		isLegacy?: boolean
		isLegacyV2?: boolean
	}
}

export const TokenComponent = (props: TokenComponentProps) => {
	const {
		name,
		unit,
		pairAddress,
		stakingAddress,
		isLegacy = false,
		isLegacyV2 = false,
	} = props.token
	const [mutate, setMutate] = useState<boolean>(false)
	const { t } = useTranslation()

	const {
		apy,
		onlyWhitelisted,
		isWhitelisted,
		pairTokenBalance,
		stakedTokenBalance,
		rewardTokenBalance,
		unlocksAt,
		approve,
		allowance,
		stake,
		unstake,
		getReward,
	} = useStakingPool(pairAddress, stakingAddress, mutate)

	const [stakeModalOpen, setStakeModalOpen] = useState<boolean>(false)
	const [signModalOpen, setSignModalOpen] = useState<boolean>(false)
	const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false)
	const [stakeType, setStakeType] = useState<'stake' | 'unstake'>('stake')

	const onStake = async (amount: number, period: number) => {
		try {
			setSignModalOpen(true)
			const tx = await stake(amount, period)
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			toast.success('Staking was successful.')
			setMutate((prev) => !prev)
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const onApprove = async () => {
		try {
			setSignModalOpen(true)
			const tx = await approve()
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			toast.success('Approving was successful.')
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const onUnstake = async (amount: number) => {
		try {
			setSignModalOpen(true)
			const tx = await unstake(amount)
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			toast.success('UnStaking was successful.')
			setMutate((prev) => !prev)
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const handleGetReward = async () => {
		try {
			setSignModalOpen(true)
			const tx = await getReward()
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			toast.success('Getting reward was successful.')
			setMutate((prev) => !prev)
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const handleStake = () => {
		setStakeType('stake')
		setStakeModalOpen(true)
	}

	const handleUnstake = () => {
		setStakeType('unstake')
		setStakeModalOpen(true)
	}

	return (
		<ContentWrapper
			className={`relative p-6 ${
				isLegacy ? 'from-[#6FFCFC80] via-[#FFFFFF70] to-[#006BA365] bg-gradient-308' : ''
			}`}
		>
			<SignTransactionModal isOpen={signModalOpen} />
			<LoadingModal isOpen={waitingModalOpen} />
			<StakeUnstakeModal
				type={stakeType}
				name={name}
				unit={unit}
				availableBalance={stakeType === 'stake' ? pairTokenBalance : stakedTokenBalance}
				unlocksAt={unlocksAt}
				isOpen={stakeModalOpen}
				allowance={allowance}
				onApprove={onApprove}
				setIsOpen={setStakeModalOpen}
				onStake={onStake}
				onUnstake={onUnstake}
			/>
			{isLegacy && (
				<div className="absolute flex h-24 w-24 items-center justify-center rounded-md border border-[#FFFFFF26] from-[#23009619] to-[#5D535320] backdrop-blur-sm backdrop-brightness-110 bg-gradient-140 lg:h-12 lg:w-12">
					<LockIcon className="lg:h-6 lg:w-6" />
				</div>
			)}
			<div className="flex flex-col items-center">
				<div className={`flex items-end gap-2.5 ${isLegacy ? 'mt-4' : ''}`}>
					<div className="flex items-center gap-6 xl:flex-col xl:gap-2">
						<div className="flex items-end gap-4">
							<img
								src={`/images/coin/${unit}.png`}
								alt=""
								className="h-20 w-20 lg:mb-3 lg:h-16 lg:w-16"
							/>
							<img src="/images/quota-coin.png" alt="" className="-ml-5 h-16 w-16" />
							<div>
								<p className="text-3xl font-bold text-orange lg:text-xl">{name} - 4.0V2</p>
								<p className="text-sm font-semibold text-primary lg:text-xs">
									{apy !== undefined ? `${(apy * props.token.multi).toFixed(2)}%` : '-'}{' '}
									<span className="text-gray">{t('APY')}</span>
								</p>
							</div>
						</div>
						{isLegacy && (
							<p className="text-2xl font-bold text-[#ED9148] lg:text-lg">
								LEGACY{isLegacyV2 ? ' V2' : ''}
							</p>
						)}
					</div>
				</div>
				<div className="mt-16 flex w-full flex-col gap-5 lg:gap-3">
					<p className="text-lg font-semibold text-gray lg:text-sm">{t('Current Rewards')}</p>
					<div className="flex items-center justify-between">
						<p className="text-2xl font-bold text-primary lg:text-base">
							{rewardTokenBalance !== undefined ? amountFormatter.format(rewardTokenBalance) : '-'}
						</p>
						<button
							className="btn lg:h-9 lg:w-[130px]"
							onClick={handleGetReward}
							disabled={!rewardTokenBalance}
						>
							{t('Collect')}
						</button>
					</div>
					<div className="border-t border-gray-3" />
					<p className="text-lg font-semibold text-gray lg:text-sm">Staked LP Tokens</p>
					<div className="flex items-center justify-between">
						<p className="text-2xl font-bold text-primary lg:text-base">
							{stakedTokenBalance !== undefined ? amountFormatter.format(stakedTokenBalance) : '-'}
						</p>
						<button
							className="btn lg:h-9 lg:w-[130px]"
							onClick={handleUnstake}
							disabled={!stakedTokenBalance}
						>
							{t('Unstake')}
						</button>
					</div>
					<div className="border-t border-gray-3" />
					<p className="text-lg font-semibold text-gray lg:text-sm">LP Token Balance</p>
					<div className="flex items-center justify-between">
						<p className="text-2xl font-bold text-primary lg:text-base">
							{pairTokenBalance !== undefined ? amountFormatter.format(pairTokenBalance) : '-'}
						</p>
						{isLegacy ? (
							<></>
						) : (
							<button
								className="btn lg:h-9 lg:w-[130px]"
								onClick={handleStake}
								disabled={(onlyWhitelisted && !isWhitelisted) || !pairTokenBalance}
							>
								{t('Stake')}
							</button>
						)}
					</div>
				</div>
			</div>
		</ContentWrapper>
	)
}

const PerpetualStakingV2 = () => {
	return (
		<div className="grid grid-cols-2 gap-10 lg:gap-5 sm:grid-cols-1">
			{perpetualStakingPools.map((token, index) => (
				<TokenComponent token={token} key={index} />
			))}
		</div>
	)
}

export default PerpetualStakingV2
