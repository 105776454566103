export const menu = [
	{ title: 'Home', to: '/home', active: true, live: false },
	{ title: 'Pro', to: '/pro', active: false, live: false },
	{ title: 'Add Liquidity', to: '/add-liquidity', active: true, live: false },
	{ title: 'Staking Pools', to: '/staking-pools', active: true, live: false },
	{ title: 'Claim Rewards', to: '/claim-rewards', active: true, live: true },
	{ title: 'Leaderboard', to: '/leaderboard', active: false, live: false },
	{ title: 'Launch Pad', to: '/launchpad', active: false, live: false },
	{ title: 'NFT', to: '/nft', active: true, live: false },
	{ title: 'Bonds', to: '/bonds', active: false, live: false },
]

export const tableData = [
	{
		timestamp: 1672704000000,
		price: 1000,
	},
	{
		timestamp: 1672790400000,
		price: 700,
	},
	{
		timestamp: 1672876800000,
		price: 1200,
	},
	{
		timestamp: 1672963200000,
		price: 1000,
	},
	{
		timestamp: 1673049600000,
		price: 990,
	},
	{
		timestamp: 1673136000000,
		price: 600,
	},
	{
		timestamp: 1673222400000,
		price: 1300,
	},
]
