import { constants } from 'ethers'
import { contracts } from './contracts'

export const pairTokens: PairToken[] = [
	{
		name: 'BNB',
		unit: 'bnb',
		address: constants.AddressZero,
		poolAddress: contracts.BNBPair,
	},
	// {
	// 	name: 'WBNB',
	// 	unit: 'bnb',
	// 	address: contracts.WBNB,
	// 	poolAddress: contracts.BNBPair,
	// },
	{
		name: 'USDT',
		unit: 'usdt',
		address: contracts.USDT,
		poolAddress: contracts.USDTPair,
	},
	{
		name: 'FIL',
		unit: 'fil',
		address: contracts.FIL,
		poolAddress: contracts.FILPair,
	},
	{
		name: 'SRVN',
		unit: 'srvn',
		address: contracts.SRVN,
		poolAddress: contracts.SRVNPair,
	},
]
