import { useEffect } from 'react'
import { apiServer } from 'utils/apiServer'
import useAppStatus from './useAppStatus'

const useASSCountDown = (mutate: boolean = false) => {
	const { countDown, setCountDown } = useAppStatus()

	useEffect(() => {
		if (countDown !== undefined) {
			return
		}

		;(async () => {
			try {
				const response = await apiServer.get('/aas/countdown')
				setCountDown(response.data?.countdown ?? 0)
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, setCountDown, countDown])

	return countDown
}

export default useASSCountDown
