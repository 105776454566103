import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { shortenAddress } from '@usedapp/core'
import { tiers } from 'config/tiers'

import ContentWrapper from 'components/Wrapper/ContentWrapper'
import { apiServer } from 'utils/apiServer'
import { amountFormatter } from 'utils/formatter'

interface Props {
	data?: NFTNode
	isOpen: boolean
	closeModal: () => void
}

const NFTDetailModal = (props: Props) => {
	const { isOpen, closeModal, data } = props
	const [totalRewards, setTotalRewards] = useState<number>()

	useEffect(() => {
		if (!data) {
			return
		}
		;(async () => {
			try {
				const response = await apiServer.get(
					`/rewards/total-rewards-collected?address=${data.ownerAddress}`
				)
				setTotalRewards(parseFloat(response.data?.collectedRewards ?? '0'))
			} catch (err) {
				console.log(err)
			}
		})()
	}, [data])

	return (
		<Modal ariaHideApp={false} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={closeModal}>
			<div className="relative">
				<img
					src={`/images/nfts/${tiers[tiers.length - 1 - (data?.tier ?? 0)].toLowerCase()}.png`}
					alt=""
					className="relative z-10 ml-[50%] -mb-10 hidden w-[112px] -translate-x-1/2 sm:block"
				/>
				<ContentWrapper className="flex w-[720px] items-center gap-8 p-8 sm:w-[340px] sm:p-6 sm:pt-12">
					<div className="flex h-80 w-64 flex-shrink-0 items-center justify-center rounded-[10px] bg-[#FFFFFF26] shadow-[inset_0px_3px_6px_#FFFFFF81,0px_3px_4px_#00000029] sm:hidden">
						<img
							src={`/images/nfts/${tiers[tiers.length - 1 - (data?.tier ?? 0)].toLowerCase()}.png`}
							alt=""
							className="w-32"
						/>
					</div>
					<div className="grid w-full gap-5 text-lg text-primary sm:gap-1 sm:text-xs">
						<div className="flex justify-between">
							<p className="font-bold">Tier</p>
							<p>{tiers[tiers.length - 1 - (data?.tier ?? 0)]}</p>
						</div>
						<div className="flex justify-between">
							<p className="font-bold">Username</p>
							<p>{data ? shortenAddress(data.ownerAddress) : '-'}</p>
						</div>
						<div className="flex justify-between">
							<p className="font-bold">Total Reward Collected</p>
							<p>{totalRewards !== undefined ? amountFormatter.format(totalRewards) : '-'}</p>
						</div>
						<div className="flex justify-between">
							<p className="font-bold">Referral under User’s NFT</p>
							<p>{data?.totalReferrals ?? '-'}</p>
						</div>
						<div className="flex justify-between">
							<p className="font-bold">Ranking</p>
							<p>#{0}</p>
						</div>
						<div className="flex justify-between">
							<p className="font-bold">Date</p>
							<p>
								{data?.mintDate
									? new Date(data.mintDate * 1000).toISOString().slice(0, 19).replace('T', ' ')
									: '-'}
							</p>
						</div>
					</div>
				</ContentWrapper>
			</div>
		</Modal>
	)
}

export default NFTDetailModal
