import { useState, useEffect } from 'react'
import { apiServer } from 'utils/apiServer'

const useTokenPrices = (type: ChartType = 'week', mutate: boolean = false) => {
	const [tokenPrices, setTokenPrices] = useState<TokenPriceType[]>([])

	useEffect(() => {
		;(async () => {
			try {
				const response = await apiServer.get(`/chart/token_price?type=${type}`)
				setTokenPrices(
					(response.data ?? []).map((item: any) => {
						return {
							timestamp: item?.time,
							open: parseFloat(item?.open),
							high: parseFloat(item?.high),
							low: parseFloat(item?.low),
							close: parseFloat(item?.close),
						} as TokenPriceType
					})
				)
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, type])

	return tokenPrices
}

export default useTokenPrices
