import { useCallback, useMemo } from 'react'
import { Contract, BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'

import { ReferralHandlerAbi, TierManagerAbi } from 'config/abis'
import { contracts } from 'config/contracts'
import { callContract } from 'utils/contracts/callContract'
import useNFTContract from './useNFTContract'
import { useEthers } from '@usedapp/core'
import { JsonRpcProvider } from '@ethersproject/providers'

const useLevelUp = () => {
	const { library } = useEthers()
	const { referralHandler } = useNFTContract()

	const tierManagerContract = useMemo(
		() =>
			new Contract(
				contracts.TierManager,
				TierManagerAbi,
				library instanceof JsonRpcProvider ? library.getSigner() : library
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[library]
	)

	const referralHandlerContract = useMemo(
		() =>
			referralHandler
				? new Contract(
						referralHandler,
						ReferralHandlerAbi,
						library instanceof JsonRpcProvider ? library.getSigner() : library
				  )
				: undefined,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[library, referralHandler]
	)

	const levelUpConditions = useCallback(
		async (level: number) => {
			const opts = {}
			const res = await callContract(tierManagerContract, 'levelUpConditions', [level], opts)

			return {
				stakedTokens: parseFloat(formatEther(res.stakedTokens)),
				stakedDuration: (res.stakedDuration as BigNumber).toNumber(),
				tierZero: (res.tierZero as BigNumber).toNumber(),
				tierOne: (res.tierOne as BigNumber).toNumber(),
				tierTwo: (res.tierTwo as BigNumber).toNumber(),
				tierThree: (res.tierThree as BigNumber).toNumber(),
			} as LevelUpConditionType
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tierManagerContract]
	)

	const levelUp = useCallback(
		async () => {
			const opts = {}
			return referralHandlerContract
				? await callContract(referralHandlerContract, 'levelUp', [], opts)
				: undefined
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[referralHandlerContract]
	)

	return {
		levelUpConditions,
		levelUp,
	}
}

export default useLevelUp
