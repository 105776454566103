import {useState, useEffect, useContext} from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Lottie from 'react-lottie-player'
import { useTranslation } from 'react-i18next'
import { shortenAddress, useEthers } from '@usedapp/core'

import { up } from 'config/lotties'
import ContentWrapper from 'components/Wrapper/ContentWrapper'
import Information from 'components/Information'
import Divider from 'components/Divider'

import LevelUpConditionModal from 'components/Modal/LevelUpCondition'
import LevelUpModal from 'components/Modal/LevelUp'
import SignTransactionModal from 'components/Modal/SignTransaction'
import LoadingModal from 'components/Modal/Loading'
import CongratulationsModal from 'components/Modal/Congratulations'

import useNFTContract from 'hooks/useNFTContract'
import useLevelUp from 'hooks/useLevelUp'
import { constants, ethers } from 'ethers'
import { tiers } from 'config/tiers'

import { ReactComponent as CopyIcon } from '@material-icons/svg/svg/content_copy/outline.svg'
import { amountFormatter } from 'utils/formatter'
import InviteFriendsModal from 'components/Modal/InviteFriends'
import {CommonContext} from "../../contexts/common";
import {RewardsContext} from "../../contexts/rewards";

const topNFTs = [
	{
		username: '0x3BddD54bfe95481ae19b05805F6f7dCfB46fB024',
		newReferrals: 16,
		stakedAmount: 3.088,
	},
	{
		username: '0x3BddD54bfe95481ae19b05805F6f7dCfB46fB024',
		newReferrals: 16,
		stakedAmount: 3.088,
	},
	{
		username: '0x3BddD54bfe95481ae19b05805F6f7dCfB46fB024',
		newReferrals: 16,
		stakedAmount: 3.088,
	},
]

const colors = {
	Bronze: '#AD5E26',
	Silver: '#888888',
	Gold: '#D5AB3C',
	Diamond: '#CAD0D8',
	Black: '#414141',
}

const Membership = ({ goToGallery }: { goToGallery: () => void }) => {
	const [searchParams] = useSearchParams()
	const [myNFT, setMyNFT] = useState<string>('Bronze')
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false)
	const [referral, setReferral] = useState<string>(searchParams.get('referralId') ?? '')

	const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false)
	const [signModalOpen, setSignModalOpen] = useState<boolean>(false)
	const [isLevelUpConditionModalOpen, setIsLevelUpConditionModalOpen] = useState<boolean>(false)
	const [isLevelUpModalOpen, setIsLevelUpModalOpen] = useState<boolean>(false)
	const [isCongratulationsModalOpen, setIsCongratulationsModalOpen] = useState<boolean>(false)
	const { t } = useTranslation()

	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'
	const { node } = useContext(CommonContext)
	const { nftTier, referralHandler, referrer, mintNFT } = useNFTContract()
	const { collectedRewards, recentRewards } = useContext(RewardsContext)
	const { levelUp } = useLevelUp()

	useEffect(() => {
		if (nftTier === undefined) {
			return
		}

		setMyNFT(tiers[tiers.length - nftTier - 1])
	}, [nftTier])

	const handleCopy = () => {
		if (!node) {
			return
		}

		navigator.clipboard.writeText(node.referralHandlerAddress)
		toast.success('Successfully copied to clipboard')
	}

	const handleLevelUpNFT = () => {
		if (myNFT === 'Black') {
			toast.error("You've reached max level!")
			return
		}
		setIsLevelUpConditionModalOpen(true)
	}

	const handleNext = () => {
		setIsLevelUpModalOpen(true)
		setIsLevelUpConditionModalOpen(false)
	}

	const onLevelUp = async () => {
		try {
			setIsLevelUpModalOpen(false)
			setSignModalOpen(true)
			const tx = await levelUp()
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			setIsCongratulationsModalOpen(true)
			toast.success('Successfully minted your NFT')
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			setIsLevelUpModalOpen(true)
			toast.error(err.message)
		}
	}

	const onClose = () => {
		setIsCongratulationsModalOpen(false)
		for (let i = 0; i < tiers.length; i++) {
			if (tiers[i + 1] === myNFT) {
				setMyNFT(tiers[i])
				return
			}
		}
	}

	const handleMint = async () => {
		try {
			if (referral.length > 0 && !ethers.utils.isAddress(referral)) {
				throw new Error('Invalid referral address')
			}

			const referralAddress = referral.length > 0 ? referral : ethers.constants.AddressZero

			setSignModalOpen(true)
			const tx = await mintNFT(referralAddress)
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			toast.success('Successfully minted your NFT')
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const handleGoToNFTGallery = () => {
		goToGallery()
	}

	const hasNFT = !account || !referralHandler || referralHandler === constants.AddressZero

	return (
		<div className="text-primary">
			<InviteFriendsModal
				referralId={referralHandler}
				isOpen={isInviteModalOpen}
				closeModal={() => setIsInviteModalOpen(false)}
			/>
			<SignTransactionModal isOpen={signModalOpen} />
			<LoadingModal isOpen={waitingModalOpen} />
			<LevelUpConditionModal
				isOpen={isLevelUpConditionModalOpen}
				closeModal={() => setIsLevelUpConditionModalOpen(false)}
				onNext={handleNext}
			/>
			{ isLevelUpModalOpen && <LevelUpModal
				isOpen={true}
				closeModal={() => setIsLevelUpModalOpen(false)}
				node={node}
				onLevelUp={onLevelUp}
				onGotoNFTGallery={handleGoToNFTGallery}
			/>}
			<CongratulationsModal
				isOpen={isCongratulationsModalOpen}
				closeModal={() => setIsCongratulationsModalOpen(false)}
				onNext={onClose}
			/>
			<div className="flex justify-center gap-12 xl:flex-col sm:gap-5">
				<div className="flex flex-shrink-0 items-center xl:w-full sm:flex-col">
					{hasNFT ? (
						<img
							src="/images/nft-logo.png"
							alt=""
							className="z-10 -mr-72 h-fit w-[400px] lg:-mr-48 lg:w-[300px] sm:mr-0 sm:-mb-24 sm:w-[225px]"
						/>
					) : (
						<img
							src={`/images/nfts/${myNFT.toLowerCase()}.png`}
							alt=""
							className="z-10 -mr-14 h-fit w-56 sm:mr-0 sm:-mb-24 sm:w-32"
						/>
					)}
					{hasNFT ? (
						<ContentWrapper className="py-24 pr-24 pl-80 xl:w-full lg:py-12 lg:pl-64 lg:pr-12 sm:px-10 sm:pt-32">
							<div className="sm:flex sm:flex-col">
								<div>
									<p className="text-[15px] font-bold uppercase sm:text-xs">{t('Username')}</p>
									<p className="text-lg">-</p>
								</div>
								<div className="mt-6">
									<div className="relative flex items-center justify-between">
										<p className="text-[15px] font-bold uppercase sm:text-xs">
											{t('REFERRER’S ID')}
										</p>
										<Information
											description={t(
												'*This NFT represents your Membership and participation on this platform. All your rewards, referrals, revenue and other related information is linked to this NFT. By minting this NFT, you are agreeing to this terms and conditions of this platform. Please note, transfering this NFT to anyone would also transfer all your referrals and future revenue to them.'
											)}
											width={280}
										/>
									</div>
									<ContentWrapper className="!rounded-[10px]">
										<input
											type="text"
											className="w-96 bg-transparent py-2 px-4 xl:w-full sm:text-xs"
											value={referral}
											onChange={(e) => setReferral(e.target.value)}
										/>
									</ContentWrapper>
								</div>
								<button
									className="btn mt-12 sm:mx-auto"
									disabled={account ? false : true}
									onClick={handleMint}
								>
									{t('Mint NFT')}
								</button>
							</div>
						</ContentWrapper>
					) : (
						<ContentWrapper className="relative py-6 pl-32 xl:w-full sm:px-10 sm:pt-24">
							<div className="absolute top-6 right-6 text-right sm:top-20 sm:right-10">
								<p className="text-[13px] text-gray">Tier</p>
								<p
									className="text-2xl font-bold leading-none sm:text-lg"
									style={{
										color: colors[myNFT as 'Bronze' | 'Silver' | 'Gold' | 'Diamond' | 'Black'],
									}}
								>
									{t(myNFT)}
								</p>
							</div>
							<div className="w-[620px] xl:w-auto">
								<p className="text-xl font-bold text-orange">{t('My NFT')}</p>
								<div className="mt-10 flex flex-col gap-5 text-sm sm:mt-6 sm:gap-3 sm:text-xs">
									<div className="flex flex-col gap-1.5">
										<p className="font-bold uppercase">{t('Username')}</p>
										<p className="overflow-hidden text-ellipsis whitespace-nowrap">{account}</p>
									</div>
									<div className="flex flex-col gap-1.5">
										<p className="font-bold uppercase">{t('REFERRER’S ID')}</p>
										<p>{referrer === constants.AddressZero ? '-' : referrer}</p>
									</div>
									<div className="flex flex-col gap-1.5">
										<p className="font-bold uppercase">{t('Referrrals Under User’s NFT')}</p>
										<p>{node?.totalReferrals ?? '-'}</p>
									</div>
									<div className="flex flex-col gap-1.5">
										<p className="font-bold uppercase">{t('Total Reward Collected')}</p>
										<p>
											{collectedRewards !== undefined
												? amountFormatter.format(collectedRewards)
												: '-'}
										</p>
									</div>
									<div className="flex flex-col gap-1.5">
										<p className="font-bold uppercase">{t('Most Recent Reward Collected')}</p>
										<p>
											{recentRewards !== undefined ? amountFormatter.format(recentRewards) : '-'}
										</p>
									</div>
								</div>
							</div>
						</ContentWrapper>
					)}
				</div>
				<div className="w-full max-w-xs xl:grid xl:max-w-none xl:grid-cols-2 xl:gap-12 sm:grid-cols-1 sm:gap-5">
					<div className="">
						<p className="text-lg underline sm:text-sm">{t('Level Up my NFT')}</p>
						<ContentWrapper className="mt-8 flex flex-col items-center px-4 py-8 xl:mt-4 sm:mt-2 sm:py-4">
							<button
								className={`btn flex w-[263px] flex-col items-center justify-center rounded-full bg-gray-5 !bg-none text-gray-dark`}
								onClick={handleLevelUpNFT}
							>
								{hasNFT ? (
									t('LEVEL UP')
								) : (
									<div className="flex items-center text-xl font-medium text-orange">
										{myNFT !== 'Black' ? (
											<div className="flex items-center">
												{t('LEVEL UP')}
												<Lottie animationData={up} loop play className="-mr-4 h-8 w-8" />
											</div>
										) : (
											t('MAX LEVEL REACHED')
										)}
									</div>
								)}
							</button>
						</ContentWrapper>
					</div>
					<div className="mt-12 xl:mt-0 sm:grid sm:grid-cols-2 sm:gap-4">
						<ContentWrapper className="hidden w-full max-w-xs flex-col items-center justify-center py-2 px-4 sm:flex">
							<div className="relative flex w-full max-w-[224px] flex-col items-center justify-center gap-4 sm:gap-2">
								<p className="text-lg sm:text-xs">{t('Invite Friends')}</p>
								<Divider className="h-1.5 w-full sm:h-1" />
								<p
									className={`text-[15px] sm:text-[10px] ${
										!hasNFT ? 'cursor-pointer underline' : ''
									}`}
									onClick={() => (!hasNFT ? setIsInviteModalOpen(true) : null)}
								>
									{!hasNFT ? t('Create a referral link') : '-'}
								</p>
							</div>
						</ContentWrapper>
						<ContentWrapper className="flex flex-col items-center px-4 py-14 xl:py-6 sm:py-2">
							<div className="relative flex w-full max-w-[224px] flex-col items-center justify-center gap-4 sm:gap-2">
								<p className="text-lg sm:text-xs">{t('My Referral ID')}</p>
								<Divider className="h-1.5 w-full sm:h-1" />
								<p className="text-[15px] sm:text-xs">
									{!hasNFT ? shortenAddress(referralHandler) : '-'}
								</p>
								<CopyIcon
									className="absolute bottom-0 right-0 cursor-pointer fill-primary text-lg sm:h-5 sm:w-5"
									onClick={handleCopy}
								/>
							</div>
						</ContentWrapper>
					</div>
				</div>
			</div>
			<p className="mt-4 ml-36 text-xl font-bold xl:mt-12 xl:ml-0 sm:mt-5 sm:text-base sm:font-normal">
				{t('Your NFT (Tier’s) Top 3 Leaderboard')}
			</p>
			<div className="flex justify-center gap-12 xl:flex-col sm:mt-2 sm:gap-5">
				<ContentWrapper className="w-full max-w-[916px] px-10 py-6 xl:max-w-none sm:px-4">
					<div className="flex gap-10 sm:flex-col sm:items-center">
						<div className="flex h-44 w-44 flex-shrink-0 items-center justify-center rounded-full bg-maroon bg-opacity-20 shadow-[inset_2px_2px_4px_#FFFFFF1F,2px_2px_4px_#00000036] sm:h-32 sm:w-32">
							{hasNFT ? (
								<img src="/images/nft-logo.png" alt="" className="h-24 w-24 sm:h-16 sm:w-16" />
							) : (
								<img
									src={`/images/nfts/${myNFT.toLowerCase()}.png`}
									alt=""
									className="h-24 w-16 sm:h-[72px] sm:w-12"
								/>
							)}
						</div>
						<table className="w-full">
							<thead className="text-left text-sm text-gray sm:text-[10px]">
								<tr>
									<th className="text-center">#</th>
									<th>{t('Username')}</th>
									<th>{t('New Referrals')}</th>
									<th>{t('Staked Amount')}</th>
								</tr>
							</thead>
							<tbody className="text-sm font-light sm:text-[10px]">
								{topNFTs.map((nft, index) => (
									<tr key={index}>
										<td>
											{t('Top')} {index + 1}
										</td>
										<td>{shortenAddress(nft.username)}</td>
										<td>{nft.newReferrals}</td>
										<td className="">
											<div className="flex justify-between">
												{nft.stakedAmount}
												<img
													src="/images/quota-coin.png"
													alt=""
													className="h-5 w-5 sm:h-4 sm:w-4"
												/>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</ContentWrapper>
				<ContentWrapper className="flex w-full max-w-xs flex-col items-center justify-center px-4 py-14 xl:mx-auto sm:hidden sm:py-4">
					<div className="relative flex w-56 flex-col items-center justify-center gap-4 sm:gap-2">
						<p className="text-lg sm:text-sm">{t('Invite Friends')}</p>
						<Divider className="h-1.5 w-full sm:h-1" />
						<p
							className={`text-[15px] sm:text-xs ${!hasNFT ? 'cursor-pointer underline' : ''}`}
							onClick={() => (!hasNFT ? setIsInviteModalOpen(true) : null)}
						>
							{!hasNFT ? t('Create a referral link') : '-'}
						</p>
					</div>
				</ContentWrapper>
			</div>
		</div>
	)
}

export default Membership
