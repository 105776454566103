import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie-player'
import { useTranslation } from 'react-i18next'

import useWindowSize from 'hooks/useWindowSize'
import { menu } from 'config'
import { live } from 'config/lotties'
import LanguageSelect from 'components/LanguageSelect'
import TokenBuyButton from 'components/Button/TokenBuy'
import WalletConnectButton from 'components/Button/WalletConnect'
import MyStats from 'components/MyStats'
import GoToLandingPageButton from 'components/Button/GoToLandingPage'
import TokenAddressButton from 'components/Button/TokenAddress'
import SocialLinks from 'components/SocialLinks'
import { ReactComponent as MenuIcon } from '@material-icons/svg/svg/menu/outline.svg'
import { ReactComponent as CloseIcon } from '@material-icons/svg/svg/close/outline.svg'

const Header = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { width } = useWindowSize()

	const handleClick = (item: any, index: number) => {
		if (item.active === false) return
		navigate(item.to)
	}

	return (
		<header className="flex h-24 flex-shrink-0 items-center">
			<div className="container md:hidden">
				<div className="flex items-end">
					<nav className="ml-auto flex gap-8 lg:gap-2">
						<TokenAddressButton />
						<TokenBuyButton />
						<WalletConnectButton />
						{(width ?? 0) >= 1024 ? <MyStats /> : <> </>}
					</nav>
				</div>
			</div>
			<div className="hidden w-full rounded-[14px] from-[#8694C73D] to-[#171F3C3D] shadow-[inset_1px_1px_3px_#FFFFFF1D] backdrop-blur-md backdrop-brightness-105 bg-gradient-100 md:flex">
				<div className="container">
					<div className="flex h-16 w-full items-center justify-between">
						<Link to="/">
							<img src="/images/logo-text-horizontal.png" alt="" className="h-10" />
						</Link>
						<div className="flex items-center gap-6">
							<WalletConnectButton variant="small" />
							<MenuIcon className="cursor-pointer fill-primary" onClick={() => setIsOpen(true)} />
						</div>
					</div>
				</div>
			</div>
			<div
				className={`scrollbar-hide fixed top-0 z-40 h-screen w-80 overflow-y-scroll rounded-l-2xl bg-[url('/public/images/background-mobile-star.png')] bg-cover ${
					isOpen ? 'right-0' : '-right-80'
				}`}
			>
				<CloseIcon
					className="absolute top-6 right-5 h-8 w-8 cursor-pointer fill-primary"
					onClick={() => setIsOpen(false)}
				/>
				<div className="mx-6 mt-16 mb-10 flex flex-col items-end">
					<TokenBuyButton />
					<div className="mt-5">
						<TokenAddressButton className="!w-[168px]" />
					</div>
					<div className="mt-5 grid gap-5">
						{menu.map((item, index) => (
							<div
								className={`relative flex flex-col items-end justify-center text-lg transition-none ${
									item.active
										? 'cursor-pointer text-primary hover:font-bold'
										: 'cursor-help text-gray-2'
								} ${pathname.includes(item.to) ? 'font-bold' : ''}`}
								key={index}
								onClick={() => handleClick(item, index)}
							>
								{item.live && (
									<Lottie
										animationData={live}
										loop
										play
										className="absolute -top-2 -left-4 h-6 w-6"
									/>
								)}
								{t(item.title)}
								{item.active === false && (
									<span className="text-[10px] font-bold uppercase">{t('Coming soon')}</span>
								)}
							</div>
						))}
					</div>
					<div className="mt-16">
						<LanguageSelect />
					</div>
					<div className="mt-12">
						<GoToLandingPageButton />
					</div>
					<div className="mt-12">
						<SocialLinks />
					</div>
				</div>
			</div>
			<div
				className={`fixed top-0 left-0 z-30 h-screen w-screen bg-[#1a101aE5] ${
					isOpen ? 'block' : 'hidden'
				}`}
				onClick={() => setIsOpen(false)}
			/>
		</header>
	)
}

export default Header
