import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Home from 'pages/Home'
import Pro from 'pages/Pro'
import AddLiquidity from 'pages/AddLiquidity'
import StakingPools from 'pages/StakingPools'
import ClaimRewards from 'pages/ClaimRewards'
import Leaderboard from 'pages/Leaderboard'
import NFT from 'pages/NFT'

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/home" replace />} />
				<Route path="home">
					<Route index element={<Home />} />
				</Route>
				<Route path="pro">
					<Route index element={<Pro />} />
				</Route>
				<Route path="add-liquidity">
					<Route index element={<AddLiquidity />} />
				</Route>
				<Route path="staking-pools">
					<Route index element={<StakingPools />} />
				</Route>
				<Route path="claim-rewards">
					<Route index element={<ClaimRewards />} />
				</Route>
				<Route path="leaderboard">
					<Route index element={<Leaderboard />} />
				</Route>
				<Route path="nft">
					<Route index element={<NFT />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default Router
