import { useCallback, useEffect, useMemo } from 'react'
import { useEthers } from '@usedapp/core'
import { BigNumber, ethers } from 'ethers'
import useSWR from 'swr'

import { useProvider } from './useProvider'
import { NFTFactoryAbi, NFTAbi, ReferralHandlerAbi } from 'config/abis'
import { contracts } from 'config/contracts'
import { callContract } from 'utils/contracts/callContract'
import { contractFetcher } from 'utils/contracts/contractFetcher'

const useNFTContract = (mutate: boolean = false) => {
	const library = useProvider()
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'

	const { data: nftId } = useSWR(['NFT.belongsTo', contracts.NFT, 'belongsTo', account], {
		fetcher: contractFetcher(library, NFTAbi),
	})

	const { data: referralHandler, mutate: referralHandlerMutate } = useSWR(
		['NFTFactory.getHandler', contracts.NFTFactory, 'getHandler', nftId],
		{
			fetcher: contractFetcher(library, NFTFactoryAbi),
		}
	)

	const { data: depositBox, mutate: depositBoxMutate } = useSWR(
		['NFTFactory.getDepositBox', contracts.NFTFactory, 'getDepositBox', nftId],
		{
			fetcher: contractFetcher(library, NFTFactoryAbi),
		}
	)

	const { data: nftTier, mutate: nftTierMutate } = useSWR(
		['ReferralHandler.getTier', referralHandler, 'getTier'],
		{
			fetcher: contractFetcher(library.getSigner(), ReferralHandlerAbi),
		}
	)

	const { data: tierCounts, mutate: tierCountsMutate } = useSWR(
		['ReferralHandler.getTierCounts', referralHandler, 'getTierCounts'],
		{
			fetcher: contractFetcher(library.getSigner(), ReferralHandlerAbi),
		}
	)

	const { data: referrer, mutate: referrerMutate } = useSWR(
		['ReferralHandler.referrer', referralHandler, 'referredBy'],
		{
			fetcher: contractFetcher(library.getSigner(), ReferralHandlerAbi),
		}
	)

	const factoryContract = useMemo(
		() => new ethers.Contract(contracts.NFTFactory, NFTFactoryAbi, library.getSigner()),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[library]
	)

	useEffect(() => {
		referralHandlerMutate(undefined, true)
		depositBoxMutate(undefined, true)
		nftTierMutate(undefined, true)
		referrerMutate(undefined, true)
		tierCountsMutate(undefined, true)
	}, [
		depositBoxMutate,
		mutate,
		nftTierMutate,
		referralHandlerMutate,
		referrerMutate,
		tierCountsMutate,
	])

	const mintNFT = useCallback(
		async (referral: string) => {
			const opts = {}
			return await callContract(factoryContract, 'mint', [referral], opts)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[factoryContract, account]
	)

	return {
		nftId,
		nftTier: nftTier ? (nftTier as BigNumber).toNumber() : undefined,
		referrer,
		referralHandler,
		depositBox,
		tierCounts,
		mintNFT,
	}
}

export default useNFTContract
