export const contracts = {
	ETF: '0x45F73dF56baBE3681447Da85A57Ab40289ec03C6',

	WBNB: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
	BNBPair: '0x9DEeF22309AD22A193D4FCb9e177811E41C40437',
	BNBStakingPool: '0x141E2db1B8a91C76c906EA537e9485859Badd9b1',

	USDT: '0x55d398326f99059ff775485246999027b3197955',
	USDTPair: '0x74f1F2Ef59f75ab011c8b292a263A5A6Aa6d68e4',
	USDTStakingPool: '0x8DbECe27c0F3A0d2e9AF634E768049e120AAFad7',

	FIL: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
	FILPair: '0x51251f22A34ec27A272aa3f5A7E892d822Cf5b22',
	FILStakingPool: '0xB5fbb81243353485988Cc7BF2A04e44855a371F3',

	SRVN: '0x15438faae0675cf7c3b28795b2c8df687f7e422b',
	SRVNPair: '0x6c3905A1cd22F004bF82de4ba1F43126434d1eB1',
	SRVNStakingPool: '0xd25D4eE77cd618Da3aBb853ee84E6cfe3fb55D10',

	SRVNLegacyStakingPool: '0x4f4FBd6c883C1Cf3C0b93A5C8599905F41e79d2A',

	SRVNLegacyV2StakingPool: '0xf5D17Bd8709F2991829411D4C4a4Bdb9E02C57BE',

	TierManager: '0xE7963463004EE1289A208527e52bee3efF1bE405',
	NFTFactory: '0xa966baE21399fD5FD66162337365DD16b881A89e',
	NFT: '0x59c8f0ccb14d4fe3556041937bc5b7df29776b87',

	LiquidityExtension: '0x50AF905d880C2b9D45aBB13c4226405fb1f9786c',
	SelfTaxCalculator: '0xF8fD9edE717708c75FbB03414d3c40CE5Ea013d9',
	// PriceFeed: '0xB6c8363ff025aed7ceC69937173eB47508105Ba9',
	// PerpetualPoolEscrow: '0xF044124C7a6C28A59Ce526FE24DB6d93Bfc06561',
	// TierPoolEscrow: '0xBD16574622C20927E36b215D61818F10dd4a3BF7',
	// TaxManager: '0x6e328aA46ad851Ef212C28a4410C1572901B9adA',
	// Taxpool: '0x9E63e18a367A15bB07879e9BB8453cEADB576828',
	// Rebaser: '0xf0121352D6B6805daB6fe3354cc089cAa573D72f',
	// Handler: '0x79A2F3B9e0Ac7e2ce1D9FA0356Dfa9543b3DA0E3',
	// DepositBox: '0x1ec7BBBe195cda53CC581309f492D05e287e8b53',
	// APYOracle: '0x18966593e9BBC5bEF457a2ab1A4bB34464770A65',
	// StakingAggregator: '0x3f327e6bc7dCD98A7b036c2aA9266c678CE66B79',
	// Notifier: '0xb01f40ce73509ba4Aa1232907823099B32449d6A',
	// StakingFactory: '0xE5EfDB2AB3CC3eFC5c8fCf7C5E1A7D87f8dC61FA',
	// RewardSplitter: '0x4eB4c0c23B262f5F0af4a87a9d21eD37A182a3fB',
	// BNBEscrowPool: '0xD69fC4c525dde3a8E0e1CcD8Cb67622E459FDC0F',
	// USDTEscrowPool: '0x7A7e8Cf3165782794117Fc81f02DF9d5627fAb90',
}
