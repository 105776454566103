import { useState, useEffect, useRef, useCallback, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useEthers } from '@usedapp/core'

import Layout from 'layouts/Layout'
import ContainerWrapper from 'components/Wrapper/ContainerWrapper'
import ContentWrapper from 'components/Wrapper/ContentWrapper'
import ContentWrapper2 from 'components/Wrapper/ContentWrapper2'
import SelectTokenModal from 'components/Modal/SelectToken'
import SignTransactionModal from 'components/Modal/SignTransaction'
import LoadingModal from 'components/Modal/Loading'
import { ReactComponent as CopyIcon } from '@material-icons/svg/svg/content_copy/outline.svg'
import { toast } from 'react-toastify'
import { pairTokens } from 'config/pairTokens'
import useERC20Token from 'hooks/useERC20Token'
import { contracts } from 'config/contracts'
import useWalletConnectModal from 'hooks/useWalletConnectModal'
import usePancakePair from 'hooks/usePancakePair'
import usePancakeRouter from 'hooks/useLiquidityExtension'
import { amountFormatter } from 'utils/formatter'
import { constants } from 'ethers'

interface InputFormProps {
	value: number
	onChange: (_value: number) => void
}

export const InputForm = (props: InputFormProps) => {
	const { value, onChange } = props

	return (
		<input
			type="number"
			className="w-full rounded-[8px] bg-[#FFFFFF2E] px-4 py-3 text-2xl text-primary shadow-[inset_0px_1px_3px_#FFFFFF81,0px_1px_2px_#00000029] md:p-2.5 md:text-xs"
			value={value}
			onChange={(e) => onChange(parseFloat(e.target.value))}
		/>
	)
}

const AddLiquidity = () => {
	const allowanceTimerRef1 = useRef<NodeJS.Timeout>()
	const allowanceTimerRef2 = useRef<NodeJS.Timeout>()
	const reserveTimerRef = useRef<NodeJS.Timeout>()
	const [mutate, setMutate] = useState<boolean>(false)
	const [quotaAmount, setQuotaAmount] = useState<number>(0)
	const [tokenAmount, setTokenAmount] = useState<number>(0)
	const [quotaApproved, setQuotaApproved] = useState<boolean>(false)
	const [tokenApproved, setTokenApproved] = useState<boolean>(false)
	const [selectedToken, setSelectedToken] = useState<PairToken>(pairTokens[0])
	const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false)
	const [signModalOpen, setSignModalOpen] = useState<boolean>(false)
	const [isSelectTokenModalOpen, setIsSelectTokenModalOpen] = useState<boolean>(false)
	const { setIsOpen } = useWalletConnectModal()
	const { t } = useTranslation()
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'

	const {
		tokenBalance: quotaBalance,
		allowance: quotaAllowance,
		approve: quotaApprove,
	} = useERC20Token(contracts.ETF, mutate)
	const {
		decimals: tokenDecimals,
		tokenBalance,
		allowance: tokenAllowance,
		approve: tokenApprove,
	} = useERC20Token(selectedToken?.address, mutate)

	const {
		reserveQuota,
		reserveToken,
		// tokenBalance: liquiditySupply,
		// totalBalance: totalLiquiditySupply,
	} = usePancakePair(selectedToken.poolAddress, tokenDecimals, mutate)
	const { addLiquidity, addLiquidityETH } = usePancakeRouter()

	const updateAllowance = useCallback(
		async (
			allowance: (value: string) => Promise<number | undefined>,
			approved: boolean,
			amount: number,
			setApproved: (value: SetStateAction<boolean>) => void
		) => {
			const allowanceBalance = (await allowance(contracts.LiquidityExtension)) ?? 0
			if (allowanceBalance > amount) {
				if (!approved) {
					setApproved(true)
				}
			} else {
				if (approved) {
					setApproved(false)
				}
			}
		},
		[]
	)

	useEffect(() => {
		if (allowanceTimerRef1.current) {
			clearTimeout(allowanceTimerRef1.current)
		}
		allowanceTimerRef1.current = setTimeout(async () => {
			await updateAllowance(quotaAllowance, quotaApproved, quotaAmount, setQuotaApproved)
			allowanceTimerRef1.current = undefined
		}, 500)
	}, [quotaAmount, updateAllowance, quotaAllowance, quotaApproved])

	useEffect(() => {
		if (allowanceTimerRef2.current) {
			clearTimeout(allowanceTimerRef2.current)
		}
		allowanceTimerRef2.current = setTimeout(async () => {
			await updateAllowance(tokenAllowance, tokenApproved, tokenAmount, setTokenApproved)
			allowanceTimerRef2.current = undefined
		}, 500)
	}, [updateAllowance, tokenAmount, tokenAllowance, tokenApproved])

	useEffect(() => {
		if (quotaAmount > (quotaBalance ?? 0)) {
			setQuotaAmount(quotaBalance ?? 0)
		}
	}, [quotaBalance, quotaAmount])

	useEffect(() => {
		if (tokenAmount > (tokenBalance ?? 0)) {
			setTokenAmount(tokenBalance ?? 0)
		}
	}, [tokenBalance, tokenAmount])

	useEffect(() => {
		if (reserveTimerRef.current) {
			clearTimeout(reserveTimerRef.current)
		}
		if (reserveQuota === undefined || reserveToken === undefined) {
			return
		}
		reserveTimerRef.current = setTimeout(async () => {
			const reserveBalance = (reserveToken * quotaAmount) / reserveQuota
			setTokenAmount(reserveBalance)
			reserveTimerRef.current = undefined
		}, 500)
	}, [quotaAmount, reserveQuota, reserveToken])

	useEffect(() => {
		if (reserveTimerRef.current) {
			clearTimeout(reserveTimerRef.current)
		}
		if (reserveQuota === undefined || reserveToken === undefined) {
			return
		}
		reserveTimerRef.current = setTimeout(async () => {
			const reserveBalance = (reserveQuota * tokenAmount) / reserveToken
			setQuotaAmount(reserveBalance)
			reserveTimerRef.current = undefined
		}, 500)
	}, [tokenAmount, reserveQuota, reserveToken])

	const handleCopyTokenAddress = () => {
		navigator.clipboard.writeText(contracts.ETF)
		toast.success('Successfully copied to clipboard')
	}

	const handleQuotaApprove = async () => {
		try {
			setSignModalOpen(true)
			const tx = await quotaApprove(contracts.LiquidityExtension)
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			toast.success('Successfully approved')
			setQuotaApproved(true)
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const handleTokenApprove = async () => {
		try {
			setSignModalOpen(true)
			const tx = await tokenApprove(contracts.LiquidityExtension)
			setSignModalOpen(false)

			console.log(tx)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			toast.success('Successfully approved')
			setTokenApproved(true)
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const handleAddLiquidity = useCallback(async () => {
		try {
			if (selectedToken.address === constants.AddressZero) {
				setSignModalOpen(true)
				const tx = await addLiquidityETH(quotaAmount, tokenAmount)
				setSignModalOpen(false)

				setWaitingModalOpen(true)
				await tx.wait()
				setWaitingModalOpen(false)
			} else {
				setSignModalOpen(true)
				const tx = await addLiquidity(
					selectedToken.address,
					quotaAmount,
					tokenAmount,
					tokenDecimals
				)
				setSignModalOpen(false)

				setWaitingModalOpen(true)
				await tx.wait()
				setWaitingModalOpen(false)
			}

			setQuotaApproved(false)
			setTokenApproved(false)
			setMutate((prev) => !prev)
			toast.success('Successfully added liquidity')
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}, [
		selectedToken.address,
		tokenAmount,
		quotaAmount,
		addLiquidity,
		addLiquidityETH,
		tokenDecimals,
	])

	return (
		<Layout title="QUOTA Webapp - Add Liquidity">
			<SelectTokenModal
				isOpen={isSelectTokenModalOpen}
				closeModal={() => setIsSelectTokenModalOpen(false)}
				onSelect={(_unit: PairToken) => setSelectedToken(_unit)}
			/>
			<SignTransactionModal isOpen={signModalOpen} />
			<LoadingModal isOpen={waitingModalOpen} />
			<ContainerWrapper title={t('Add Liquidity')}>
				<ContentWrapper className="mx-auto w-full max-w-[1200px] p-12 md:border-none md:bg-none md:p-0 md:shadow-none md:backdrop-blur-none">
					<ContentWrapper2 className="px-8 py-10 md:px-5 md:py-3">
						<p className="text-4 md:text-xs">
							<span className="font-bold text-orange">{t('Tip')}: </span>
							{t(
								'When you add liquidity, you will receive LP tokens corresponding to the amount of your share of the pool. These tokens can be used to stake from the staking pools page. Staking these LP tokens will accrue rewards over time.'
							)}
						</p>
					</ContentWrapper2>
					<div className="mt-12 flex items-center lg:flex-col md:mt-5">
						<ContentWrapper2 className="w-full p-6 md:p-4">
							<div className="flex items-center justify-between gap-1">
								<InputForm value={quotaAmount} onChange={setQuotaAmount} />
								<div className="flex flex-shrink-0 items-center gap-1">
									<img src="/images/quota-coin.png" alt="" className="h-9 w-9 md:h-7 md:w-7" />
									<p className="text-2xl font-bold italic text-primary md:text-lg">4.0V2</p>
								</div>
							</div>
							<p className="mt-6 text-right text-base text-primary md:mt-4 md:text-xs">
								{t('Balance')}:{' '}
								{quotaBalance !== undefined ? amountFormatter.format(quotaBalance) : '-'}
							</p>
						</ContentWrapper2>
						<span className="mx-4 text-[32px] font-bold text-primary">+</span>
						<ContentWrapper2 className="w-full p-6 md:p-4">
							<div className="flex items-center justify-between gap-1">
								<div className="relative w-full">
									<InputForm value={tokenAmount} onChange={setTokenAmount} />
									<button
										className="absolute top-1/2 right-0 h-6 w-12 -translate-y-1/2 -translate-x-1/4 rounded-full bg-gray text-xs text-orange shadow-[3px_3px_6px_#00000043] md:h-4 md:w-8 md:text-[10px]"
										onClick={() => setTokenAmount(tokenBalance ?? 0)}
									>
										{t('MAX')}
									</button>
								</div>
								<button
									className="btn h-14 w-32 flex-shrink-0 md:h-9 md:w-20 md:text-[10px]"
									onClick={() => setIsSelectTokenModalOpen(true)}
								>
									{!selectedToken ? (
										'Select Token'
									) : (
										<div className="flex items-center justify-center gap-2 text-xl font-bold uppercase italic md:text-xs md:font-normal">
											<img
												src={`/images/coin/${selectedToken.unit}.png`}
												alt=""
												className="h-8 w-8 md:h-6 md:w-6"
											/>
											{selectedToken.name}
										</div>
									)}
								</button>
							</div>
							<p className="mt-6 text-right text-base text-primary md:mt-4 md:text-xs">
								{t('Balance')}:{' '}
								{tokenBalance !== undefined ? amountFormatter.format(tokenBalance) : '-'}
							</p>
						</ContentWrapper2>
					</div>
					<div
						className="mt-2 flex w-fit cursor-pointer items-center gap-1"
						onClick={handleCopyTokenAddress}
					>
						<span className="text-xs text-primary underline">{t('Token Address')}</span>
						<CopyIcon className="h-4 w-4 fill-primary" />
					</div>
					{account && selectedToken && (
						<>
							<ContentWrapper2 className="mx-auto mt-4 max-w-3xl px-6 py-4 md:p-2">
								<p className="text-primary md:text-xs">{t('Prices and pool share')}</p>
								<div className="mt-2 flex items-center justify-around rounded-[8px] bg-[#FFFFFF2E] px-4 py-2 shadow-[inset_0px_1px_3px_#FFFFFF81,0px_1px_2px_#00000029]">
									<div className="flex flex-col items-center">
										<span className="text-primary md:text-[10px]">
											{reserveQuota && reserveToken
												? amountFormatter.format(reserveToken / reserveQuota)
												: '-'}
										</span>
										<span className="text-xs text-gray md:text-[8px]">{`${selectedToken.name} per 4.0V2`}</span>
									</div>
									<div className="flex flex-col items-center">
										<span className="text-primary md:text-[10px]">
											{reserveQuota && reserveToken
												? amountFormatter.format(reserveQuota / reserveToken)
												: '-'}
										</span>
										<span className="text-xs text-gray md:text-[8px]">{`4.0V2 per ${selectedToken.name}`}</span>
									</div>
									<div className="flex flex-col items-center">
										<span className="text-primary md:text-[10px]">
											{reserveQuota !== undefined
												? ((quotaAmount * 100) / (reserveQuota + quotaAmount)).toFixed(2) + '%'
												: '-'}
										</span>
										<span className="text-xs text-gray md:text-[8px]">{t('Share of Pool')}</span>
									</div>
								</div>
							</ContentWrapper2>
							<div className="flex flex-col items-center">
								<div className="mt-8 grid w-full max-w-lg grid-cols-2 gap-x-5 gap-y-4 md:gap-x-3">
									{quotaApproved && tokenApproved ? (
										<button className="btn col-span-2 h-12 w-full" onClick={handleAddLiquidity}>
											{t('Supply')}
										</button>
									) : (
										<>
											<button
												className="btn h-12 w-full"
												disabled={quotaApproved}
												onClick={handleQuotaApprove}
											>
												{t('Approve')} 4.0V2
											</button>
											<button
												className="btn h-12 w-full"
												disabled={tokenApproved}
												onClick={handleTokenApprove}
											>
												{`${t('Approve')} ${selectedToken.name}`}
											</button>
										</>
									)}
								</div>
							</div>
						</>
					)}
					<div className="flex flex-col items-center">
						{!account && (
							<button
								className="btn mt-24 h-12 w-full max-w-lg md:mt-12"
								onClick={() => setIsOpen(true)}
							>
								{t('Connect Wallet')}
							</button>
						)}
						{account && !selectedToken && (
							<button className="btn mt-24 h-12 w-full max-w-lg md:mt-12" disabled>
								{t('Invalid pair')}
							</button>
						)}
					</div>
				</ContentWrapper>
			</ContainerWrapper>
		</Layout>
	)
}

export default AddLiquidity
