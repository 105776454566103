import { useEffect } from 'react'
import { apiServer } from 'utils/apiServer'
import { formatEther } from 'ethers/lib/utils'
import useERC20Token from './useERC20Token'
import { contracts } from 'config/contracts'
import { useEthers } from '@usedapp/core'
import useAppStatus from './useAppStatus'

const useCurrentData = (mutate: boolean = false) => {
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'
	const {
		tvl,
		setTvl,
		totalSupply,
		setTotalSupply,
		circulatingSupply,
		setCirculatingSupply,
		stakedBalance,
		marketValue,
		setMarketValue,
		tokenPrice,
	} = useAppStatus()
	const { tokenBalance } = useERC20Token(contracts.ETF)

	useEffect(() => {
		if (tvl !== undefined) {
			return
		}

		;(async () => {
			try {
				const response = await apiServer.get('/token/tvl')
				setTvl(parseFloat(formatEther(response.data?.tvl ?? '0')))
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, tvl, setTvl])

	useEffect(() => {
		if (totalSupply !== undefined) {
			return
		}

		;(async () => {
			try {
				const response = await apiServer.get('/token/total_supply')
				setTotalSupply(parseFloat(formatEther(response.data?.totalSupply ?? '0')))
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, totalSupply, setTotalSupply])

	useEffect(() => {
		if (circulatingSupply !== undefined) {
			return
		}

		;(async () => {
			try {
				const response = await apiServer.get('/token/circulating_supply')
				setCirculatingSupply(parseFloat(formatEther(response.data?.circulatingSupply ?? '0')))
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, circulatingSupply, setCirculatingSupply])

	useEffect(() => {
		if (!account || marketValue !== undefined) {
			return
		}
		;(async () => {
			try {
				const response = await apiServer.get(`/rewards/current-market-value?address=${account}`)
				setMarketValue(parseFloat(response.data?.currentMarketValue ?? '0'))
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, account, setMarketValue, marketValue])

	return {
		tokenPrice,
		tvl,
		totalSupply,
		circulatingSupply,
		tokenBalance,
		stakedBalance,
		marketValue,
	}
}

export default useCurrentData
