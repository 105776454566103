import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { Line } from 'rc-progress'
import { useEthers } from '@usedapp/core'

import ModalWrapper from 'components/Wrapper/ModalWrapper'
import { ReactComponent as CloseIcon } from '@material-icons/svg/svg/close/outline.svg'
import { ReactComponent as RightArrowIcon } from '@material-icons/svg/svg/trending_flat/outline.svg'
import ContentWrapper from 'components/Wrapper/ContentWrapper'
import Divider from 'components/Divider'
import Information from 'components/Information'
import useLevelUp from 'hooks/useLevelUp'
import { formatEther } from 'ethers/lib/utils'
import { perpetualStakingPools } from 'config/perpetualStakingPools'
import useERC20Token from 'hooks/useERC20Token'
import useNFTContract from 'hooks/useNFTContract'
import { BigNumber } from 'ethers'
import { arrow } from 'config/lotties'
import Lottie from 'react-lottie-player'
import useAppStatus from 'hooks/useAppStatus'

const tiers = ['Bronze', 'Silver', 'Gold', 'Diamond', 'Black']

interface Props {
	node: NFTNode | undefined
	isOpen: boolean
	closeModal: () => void
	onLevelUp: () => void
	onGotoNFTGallery: () => void
}

const LevelUpModal = (props: Props) => {
	const { account } = useEthers()
	const { isOpen, closeModal, onLevelUp, onGotoNFTGallery } = props
	const { levelUpConditions } = useLevelUp()
	const { balanceOf } = useERC20Token(undefined)
	const { stakedBalance } = useAppStatus()
	const { tierCounts, nftTier } = useNFTContract()
	const [currentConditions, setCurrentConditions] = useState<LevelUpConditionType>()
	const [nextConditions, setNextConditions] = useState<LevelUpConditionType>()
	const [selectedToken, setSelectedToken] = useState<string>('')
	// const [totalStakedBalance, setTotalStakedBalance] = useState<number>(0)
	const [stakableTokens, setStakableTokens] = useState<any[]>([])
	const { t } = useTranslation()

	useEffect(() => {
		;(async () => {
			let condition = await levelUpConditions(nftTier ?? 0)
			setCurrentConditions(condition)
			condition = await levelUpConditions((nftTier ?? 0) + 1)
			setNextConditions(condition)
		})()
	}, [nftTier, levelUpConditions])

	// useEffect(() => {
	// 	if (!node) {
	// 		return
	// 	}

	// 	setTotalStakedBalance(
	// 		(node.stakedBalance ?? [])
	// 			.map((item) => parseFloat(formatEther(item.stakedBalance)))
	// 			.reduce((a, b) => a + b, 0)
	// 	)
	// }, [node])

	useEffect(() => {
		;(async () => {
			setStakableTokens([])
			const temp: any[] = []
			for (const token of perpetualStakingPools) {
				const poolAmount = await balanceOf(token.pairAddress, account as string)
				if (poolAmount > 0) {
					temp.push(token)
					setStakableTokens(temp)
				}
			}
		})()
	}, [account, balanceOf])

	const handleGoToNFTGallery = () => {
		onGotoNFTGallery()
	}

	const canLevelUp = useMemo(
		() =>
			tierCounts &&
			nextConditions &&
			(stakedBalance ?? 0) >= (nextConditions?.stakedTokens ?? 0) &&
			tierCounts[0] >= nextConditions.tierZero &&
			tierCounts[1] >= nextConditions.tierOne &&
			tierCounts[2] >= nextConditions.tierTwo &&
			tierCounts[3] >= nextConditions.tierOne &&
			tierCounts[4] >= nextConditions.tierThree,
		[tierCounts, nextConditions, stakedBalance]
	)

	return (
		<Modal ariaHideApp={false} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={closeModal}>
			<ModalWrapper className="!w-[1000px] md:!w-[360px] md:p-5">
				<CloseIcon
					className="absolute top-8 right-6 h-6 w-6 cursor-pointer fill-primary sm:top-3 sm:right-3"
					onClick={closeModal}
				/>
				<h1 className="modal-header">Level Up</h1>
				<div className="mt-6 flex items-center md:hidden md:justify-between">
					{tiers.map((_tier, index) => (
						<React.Fragment key={index}>
							<ContentWrapper
								className={`flex-shrink-0 md:!rounded-md md:p-3 ${
									index === nftTier
										? 'mx-2 p-4'
										: 'border-none !bg-none opacity-50 shadow-none backdrop-blur-none backdrop-brightness-100'
								}`}
								key={index}
							>
								<div className="flex flex-col items-center gap-3">
									<div
										className={`text-xs font-bold text-primary md:hidden ${
											index === nftTier ? 'visible' : 'invisible'
										}`}
									>
										{t('YOUR CURRENT LEVEL')}
										<Divider />
									</div>
									<img
										src={`/images/nfts/${_tier.toLowerCase()}.png`}
										alt=""
										className="w-[92px] md:w-7"
									/>
									<span className="text-primary md:text-xs">{t(_tier)}</span>
								</div>
							</ContentWrapper>
							{/* {index < 4 ? <RightArrowIcon className="w-full fill-orange md:hidden" /> : <></>} */}
							{index < 4 && index !== nftTier ? (
								<div className="flex w-full justify-between px-2">
									{new Array(3).fill(0).map((_, index) => (
										<div className="h-2 w-2 rounded-full bg-gray-dark" key={index} />
									))}
								</div>
							) : index === nftTier ? (
								<Lottie animationData={arrow} loop play className="w-full" />
							) : (
								<></>
							)}
						</React.Fragment>
					))}
				</div>
				<div className="mt-4 hidden items-center justify-between md:flex">
					{tiers.map((_tier, index) =>
						nftTier === index || nftTier === index - 1 ? (
							<React.Fragment key={index}>
								<ContentWrapper
									className={`flex-shrink-0 rounded-md ${
										index === nftTier
											? 'mx-2 p-3'
											: 'border-none !bg-none opacity-50 shadow-none backdrop-blur-none backdrop-brightness-100'
									}`}
									key={index}
								>
									<div className="flex flex-col items-center gap-3">
										<div
											className={`text-[10px] font-bold text-primary ${
												index === nftTier ? 'visible' : 'invisible'
											}`}
										>
											{t('YOUR CURRENT LEVEL')}
											<Divider />
										</div>
										<img src={`/images/nfts/${_tier.toLowerCase()}.png`} alt="" className="w-16" />
										<span className="text-primary md:text-xs">{t(_tier)}</span>
									</div>
								</ContentWrapper>
								{nftTier === index && <Lottie animationData={arrow} loop play className="w-full" />}
							</React.Fragment>
						) : (
							<></>
						)
					)}
				</div>
				<div className="mt-12 flex gap-24 text-primary md:mt-8 md:flex-col md:gap-4">
					<div className="relative flex flex-shrink-0 flex-col gap-1">
						<div className="absolute right-0 -top-3">
							<Information
								description="You will only be able to select the pair pool that you have the corresponding LP token amount required for level up. If unable to stake here, please go to the Staking Pool."
								width={200}
							/>
						</div>
						<p className="md:text-xs">{t('Choose Staking pool')}</p>
						<div className="grid gap-3 rounded-[8px] bg-[#1B193B] p-3 shadow-[inset_3px_3px_4px_#FFFFFF1A] md:grid-cols-2 md:gap-1 md:p-1">
							{stakableTokens.map((token, index) => (
								<ContentWrapper
									className={`cursor-pointer !rounded-md py-2 px-10 ${
										token.unit === selectedToken ? '!from-orange !to-[#E57E35]' : ''
									} md:px-4 md:py-1`}
									key={index}
									onClick={() => setSelectedToken(token.unit)}
								>
									<div className="flex items-center gap-1.5">
										<img src={`/images/coin/${token.unit}.png`} alt="" className="h-7 w-7" />
										{token.name}
									</div>
								</ContentWrapper>
							))}
						</div>
					</div>
					<div className="flex w-full flex-col">
						<div>
							<div className="flex justify-between text-xs md:text-[8px]">
								<p>{t('MINIMUM REQUIRED TOKENS STAKED')}:</p>
								<p className="relative">
									<div className="absolute -left-3 -top-3">
										<Information width={180}>
											<p>
												This is the minimum number of tokens required to be staked for your level
												up. You will only be able to stake here if you have the corresponding LP
												token amount required for level up. If unable to stake here, please go to
												the Staking Pool.
											</p>
										</Information>
									</div>
									{t('MINIMUM STAKE REQUIRED FOR LEVEL UP')}: {nextConditions?.stakedTokens}
								</p>
							</div>
							<div className="mt-1 rounded-[8px] bg-[#1B193B] p-4 pb-1 shadow-[inset_3px_3px_4px_#FFFFFF1A] md:p-2 md:pb-1">
								<Line
									percent={
										stakedBalance && nextConditions && currentConditions
											? ((stakedBalance - currentConditions.stakedTokens) * 100) /
											  (nextConditions.stakedTokens - currentConditions.stakedTokens)
											: 0
									}
									strokeWidth={0.5}
									strokeColor="#AD5E26"
								/>
								<div className="flex items-center justify-between md:text-xs">
									<p>{currentConditions?.stakedTokens ?? '-'}</p>
									<div className="flex items-center gap-0.5 text-orange">
										{nextConditions?.stakedTokens ?? '-'}
										<img src="/images/quota-coin.png" alt="" className="h-5 w-5 md:h-3 md:w-3" />
									</div>
								</div>
							</div>
						</div>
						<div className="mt-6 md:mt-2">
							<div className="flex justify-between text-xs md:text-[8px]">
								<p className="relative">
									{t('MINIMUM REQUIRED REFERRALS FOR NEXT TIER')}:
									<div className="group absolute -top-2 -right-4 flex h-3.5 w-3.5 items-center justify-center rounded border border-white font-bold">
										i
										<div className="absolute top-0 left-0 hidden -translate-x-1/2 -translate-y-full group-hover:block md:-translate-x-32">
											<div className="flex w-[720px] flex-col items-center rounded-[20px] bg-white p-12 font-normal text-gray-4 md:w-[320px] md:p-4">
												<p className="mb-4 text-base font-semibold">
													Referral Requirements for Level Up
												</p>
												<div className="grid grid-cols-4 gap-4 md:grid-cols-1">
													<div>
														<p className="text-base font-medium md:text-sm">Silver</p>
														<p className="text-sm md:text-xs">
															Minimum of 3 Bronze referrals below
														</p>
													</div>
													<div>
														<p className="text-base font-medium md:text-sm">Gold</p>
														<p className="text-sm md:text-xs">
															Minimum of 10 Bronze referrals + 5 Silver referrals below
														</p>
													</div>
													<div>
														<p className="text-base font-medium md:text-sm">Diamond</p>
														<p className="text-sm md:text-xs">
															Minimum of 20 Bronze referrals + 10 Silver referrals + 5 Gold
															referrals below
														</p>
													</div>
													<div>
														<p className="text-base font-medium md:text-sm">Black</p>
														<p className="text-sm md:text-xs">
															Minimum of 50 Bronze referrals + 20 Silver referrals + 10 Gold
															referrals + 5 Diamond referrals below
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</p>
								<p className="cursor-pointer underline" onClick={handleGoToNFTGallery}>
									{t('Go to NFT Gallery')}
								</p>
							</div>
							<div className="mt-1 w-full rounded-[8px] bg-[#1B193B] p-4 shadow-[inset_3px_3px_4px_#FFFFFF1A] md:p-2">
								<p className="md:text-xs">Current Referrals</p>
								<div className="mt-6 grid w-full grid-cols-4 md:mt-2 md:text-xs">
									<div className="flex flex-col items-center gap-2 border-r border-gray">
										<p>Bronze</p>
										<p className="text-3xl font-bold text-orange md:text-sm">{`${
											tierCounts ? (tierCounts[0] as BigNumber).toNumber() : '-'
										}/${nextConditions ? nextConditions.tierZero : '-'}`}</p>
									</div>
									<div className="flex flex-col items-center gap-2 border-r border-gray">
										<p>Silver</p>
										<p className="text-3xl font-bold text-orange md:text-sm">{`${
											tierCounts ? (tierCounts[1] as BigNumber).toNumber() : '-'
										}/${nextConditions ? nextConditions.tierOne : '-'}`}</p>
									</div>
									<div className="flex flex-col items-center gap-2 border-r border-gray">
										<p>Gold</p>
										<p className="text-3xl font-bold text-orange md:text-sm">{`${
											tierCounts ? (tierCounts[2] as BigNumber).toNumber() : '-'
										}/${nextConditions ? nextConditions.tierTwo : '-'}`}</p>
									</div>
									<div className="flex flex-col items-center gap-2">
										<p>Diamond</p>
										<p className="text-3xl font-bold text-orange md:text-sm">{`${
											tierCounts ? (tierCounts[3] as BigNumber).toNumber() : '-'
										}/${nextConditions ? nextConditions.tierThree : '-'}`}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-6 flex items-center justify-between md:mt-4">
							<div>
								<p className="text-xs md:text-[10px]">{t('REQUIRED LOCK DURATION')}:</p>
								<p className="text-lg text-orange md:text-xs">
									{nextConditions?.stakedDuration ?? '-'} {t('DAYS')}
								</p>
							</div>
							<button className="btn" onClick={onLevelUp} disabled={!canLevelUp}>
								{t('Level Up NFT')}
							</button>
						</div>
					</div>
				</div>
			</ModalWrapper>
		</Modal>
	)
}

export default LevelUpModal
