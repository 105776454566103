import { useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie-player'

import { menu } from 'config'
import { live } from 'config/lotties'
import LanguageSelect from 'components/LanguageSelect'
import GoToLandingPageButton from 'components/Button/GoToLandingPage'
import SocialLinks from 'components/SocialLinks'

const Sidebar = () => {
	const [activeIndex, setActiveIndex] = useState<number>(
		Number(localStorage.getItem('activeIndex')) || 0
	)
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { t } = useTranslation()

	useEffect(() => {
		for (let i = 0; i < menu.length; i++) {
			if (pathname.includes(menu[i].to)) {
				setActiveIndex(i)
				localStorage.setItem('activeIndex', `${i}`)
			}
		}
	}, [pathname])

	const handleClick = (item: any) => {
		if (item.active === false) return
		navigate(item.to)
	}

	return (
		<aside className="scrollbar-hide relative h-screen w-64 flex-shrink-0 overflow-y-auto md:hidden">
			<div
				className="absolute top-0 left-0 w-64 rounded-r-[20px] border border-l-0 border-[#FFFFFF26] from-[#23009619] to-[#5D535321] shadow-[inset_0px_3px_6px_#0000002F] backdrop-blur-lg backdrop-brightness-110 bg-gradient-129"
				style={{ height: 203 + activeIndex * 60 }}
			/>
			<div
				className="absolute left-0 w-64 rounded-r-[20px] border border-l-0 border-[#FFFFFF26] from-[#23009619] to-[#5D535321] shadow-[inset_0px_3px_6px_#0000002F] backdrop-blur-lg backdrop-brightness-110 bg-gradient-129"
				style={{
					top: 263 + activeIndex * 60,
					height: (menu.length - activeIndex - 1) * 60 + 360,
				}}
			/>
			<div className="relative z-[1] mt-[25px] mb-10 flex flex-col items-center">
				<Link to="/">
					<img src="/images/logo-text-vertical.png" alt="" className="w-[115px]" />
				</Link>
				<div className="mt-20 w-full">
					<div className="grid">
						{menu.map((item, index) => (
							<div
								className={`relative flex h-[60px] flex-col justify-center px-10 text-lg transition-none ${
									item.active
										? 'cursor-pointer text-primary hover:font-bold'
										: 'cursor-help text-gray-2'
								} ${pathname.includes(item.to) ? 'font-bold' : ''}`}
								key={index}
								onClick={() => handleClick(item)}
							>
								{item.live && (
									<Lottie
										animationData={live}
										loop
										play
										className="absolute top-1 left-4 h-6 w-6"
									/>
								)}
								{t(item.title)}
								{item.active === false && (
									<span className="text-[10px] font-bold uppercase">{t('Coming soon')}</span>
								)}
							</div>
						))}
					</div>
					<div className="mt-16 px-10">
						<LanguageSelect />
					</div>
					<div className="mt-16 px-10">
						<GoToLandingPageButton />
					</div>
					<div className="mt-8 px-10">
						<SocialLinks />
					</div>
				</div>
				<div className="mt-6">
					<p className="text-center text-[10px] text-primary">
						&copy; 2023 QUOTA LAB
						<br />
						ALL RIGHTS RESERVED
					</p>
				</div>
			</div>
		</aside>
	)
}

export default Sidebar
