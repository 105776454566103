import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const buttons = [
	{ name: '7D', period: 7, type: 'week' },
	{ name: '30D', period: 30, type: 'month' },
	{ name: 'All', period: -1, type: 'all' },
]

interface Props {
	onPeriodChange: (period: ChartType) => void
}

const ChartPeriodButton = (props: Props) => {
	const { onPeriodChange } = props
	const [period, setPeriod] = useState<ChartType>('week')
	const { t } = useTranslation()

	const handleClick = (_period: ChartType) => {
		setPeriod(_period)
		onPeriodChange(_period)
	}

	return (
		<div className="flex gap-5 sm:gap-2">
			{buttons.map((button, index) => (
				<button
					className={`h-9 w-9 rounded border border-primary text-[14px] sm:h-6 sm:w-7 sm:text-[10px] ${
						button.type !== period ? 'text-primary' : ' bg-primary font-bold text-orange'
					}`}
					key={index}
					onClick={() => handleClick(button.type as ChartType)}
				>
					{t(button.name)}
				</button>
			))}
		</div>
	)
}

export default ChartPeriodButton
