import { useEffect } from 'react'
import { useEthers } from '@usedapp/core'
import { formatUnits } from 'ethers/lib/utils'
import useSWR from 'swr'

import { useProvider } from './useProvider'
import { PancakePairAbi } from 'config/abis'
import { contractFetcher } from 'utils/contracts/contractFetcher'
import { contracts } from 'config/contracts'

const usePancakePair = (
	address: string | undefined,
	decimals: string | undefined,
	mutate: boolean = false
) => {
	const library = useProvider()
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'

	const { data: totalBalance, mutate: totalBalanceMutate } = useSWR(
		['Pair.totalSupply', address, 'totalSupply'],
		{
			fetcher: contractFetcher(library, PancakePairAbi),
		}
	)

	const { data: tokenBalance, mutate: tokenBalanceMutate } = useSWR(
		['Pair.balanceOf', address, 'balanceOf', account],
		{
			fetcher: contractFetcher(library, PancakePairAbi),
		}
	)

	const { data: reserves, mutate: reservesMutate } = useSWR(
		['Pair.decimals', address, 'getReserves'],
		{
			fetcher: contractFetcher(library, PancakePairAbi),
		}
	)

	const { data: token0 } = useSWR(['Pair.token0', address, 'token0'], {
		fetcher: contractFetcher(library, PancakePairAbi),
	})

	useEffect(() => {
		tokenBalanceMutate(undefined, true)
		reservesMutate(undefined, true)
		totalBalanceMutate(undefined, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, mutate, tokenBalanceMutate, reservesMutate, totalBalanceMutate])

	return {
		reserveQuota:
			reserves && token0
				? token0 === contracts.ETF
					? parseFloat(formatUnits(reserves._reserve0, 18))
					: parseFloat(formatUnits(reserves._reserve1, 18))
				: undefined,
		reserveToken:
			reserves && token0
				? token0 === contracts.ETF
					? parseFloat(formatUnits(reserves._reserve1, 18))
					: parseFloat(formatUnits(reserves._reserve0, 18))
				: undefined,
		tokenBalance: tokenBalance ? parseFloat(formatUnits(tokenBalance, 18)) : undefined,
		totalBalance: totalBalance ? parseFloat(formatUnits(totalBalance, 18)) : undefined,
	}
}

export default usePancakePair
