import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContentWrapper from 'components/Wrapper/ContentWrapper'
import ChartForm from '.'
import ChartPeriodButton from 'components/Button/ChartPeriod'

interface Props {
	title?: string | null
	isInfo?: boolean
	data?: ChartDataType[]
	type: 'area' | 'bar' | 'line' | 'polygon'
	onPeriodChange: (period: ChartType) => void
}

const ProChart = (props: Props) => {
	const { title, isInfo = false, data, type, onPeriodChange } = props
	const [isHover, setIsHover] = useState<boolean>(false)
	const { t } = useTranslation()

	const handlePeriodChange = (_period: ChartType) => {
		onPeriodChange(_period)
	}

	return (
		<ContentWrapper className="p-6">
			<div className="flex flex-col">
				<div className="relative flex items-center justify-between">
					<div className="flex gap-1 text-2xl text-primary md:text-lg sm:text-sm">
						{title}
						<div
							className={`${
								isInfo ? 'flex' : 'hidden'
							} relative h-3 w-3 cursor-pointer items-center justify-center rounded border border-primary text-[10px] text-primary`}
							onMouseEnter={() => setIsHover(true)}
							onMouseLeave={() => setIsHover(false)}
						>
							i
						</div>
					</div>
					<div className="mt-4 ml-auto mr-[30px] sm:mr-0">
						<ChartPeriodButton onPeriodChange={handlePeriodChange} />
					</div>
					<div
						className={`${
							isInfo && isHover ? 'block' : 'hidden'
						} absolute bottom-0 left-0 z-10 w-full max-w-[480px] translate-y-full rounded-lg bg-primary p-4 text-xs sm:p-2 sm:text-[10px]`}
					>
						{t(
							"AAS: 'Automatically Adjusted Supply' is Quota's algorithmic equilibrium seeking token supply moderator. The price action of 4.0 is systematically logged in 1 hour intervals over a 24 hour period to determine the degree of deviation from S&P 500. This deviation data is then processed in out Automatically Adjusted Supply ('AAS') algorithm, to adjust the token supply accordingly."
						)}
						<br />
						<br />
						<b>
							<i>*Automatic Supply Adjustment AAS occurs once a day at 12am UTC.</i>
						</b>
					</div>
				</div>
				<div className="h-80 w-full md:h-64 sm:h-40">
					<ChartForm data={data} type={type} />
				</div>
			</div>
		</ContentWrapper>
	)
}

export default ProChart
