import {createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo} from "react";
import {useProvider} from "../hooks/useProvider";
import {useEthers} from "@usedapp/core";
import useNFTContract from "../hooks/useNFTContract";
import useReferralRewards from "../hooks/useReferralReward";
import useAppStatus from "../hooks/useAppStatus";
import useSWR from "swr";
import {contracts} from "../config/contracts";
import {contractFetcher} from "../utils/contracts/contractFetcher";
import {DepositBoxAbi, ERC20Abi, SelfTaxCalculatorAbi} from "../config/abis";
import {BigNumber, Contract} from "ethers";
import {callContract} from "../utils/contracts/callContract";
import {apiServer} from "../utils/apiServer";
import {formatEther} from "ethers/lib/utils";

interface IRewardsContext {
    collectedRewards?: number;
    marketValue?: number;
    stakedBalance?: number;
    poolRewards?: number;
    depositBox?: number;
    tokenPrice?: number;
    recentRewards?: number;
    remainingClaims: number;
    referralHandlerBalance: number;
    depositBoxBalance?: number;
    claimReferralReward: () => Promise<any>;
    claimDepositBoxReward: () => Promise<any>;
}

export const RewardsContext = createContext<IRewardsContext>(null as any);

export const RewardsContextProvider: FC<PropsWithChildren> = ({children}) => {
    const library = useProvider()
    const { account } = useEthers()
    const { depositBox } = useNFTContract()
    const { remainingClaims, referralHandlerBalance, claimReferralReward } = useReferralRewards()
    const {
        marketValue,
        setMarketValue,
        tokenPrice,
        collectedRewards,
        setCollectedRewards,
        stakedBalance,
        setStakedBalance,
        poolRewards,
        setPoolRewards,
        recentRewards,
        setRecentRewards,
    } = useAppStatus();

    const { data: depositBoxBalance, mutate: depositBoxBalanceMutate } = useSWR(
        ['ETF.balanceOf', contracts.ETF, 'balanceOf', depositBox],
        {
            fetcher: contractFetcher(library, ERC20Abi),
        }
    )

    const { data: selfTax, mutate: selfTaxMutate } = useSWR(
        ['ETF.SelfTaxCalculator', contracts.SelfTaxCalculator, 'getSelfTax', account],
        {
            fetcher: contractFetcher(library, SelfTaxCalculatorAbi),
        }
    )

    const depositBoxContract = useMemo(
        () => (depositBox ? new Contract(depositBox, DepositBoxAbi, library.getSigner()) : undefined),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [library, depositBox]
    )

    const claimDepositBoxReward = useCallback(
        async () => {
            const opts = {}
            return depositBoxContract
                ? await callContract(depositBoxContract, 'claimReward', [], opts)
                : undefined
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [depositBoxContract, account]
    )

    useEffect(() => {
        if (!account || collectedRewards !== undefined) {
            return
        }
        ;(async () => {
            try {
                const response = await apiServer.get(`/rewards/total-rewards-collected?address=${account}`)
                setCollectedRewards(parseFloat(formatEther(response.data?.collectedRewards ?? '0')))
            } catch (err) {
                console.log(err)
            }
        })()
    }, [account, setCollectedRewards, collectedRewards])

    useEffect(() => {
        if (!account || stakedBalance !== undefined) {
            return
        }
        ;(async () => {
            try {
                const response = await apiServer.get(`/pool/staked-balance?address=${account}`)
                setStakedBalance(parseFloat(formatEther(response.data?.staked ?? '0')))
            } catch (err) {
                console.log(err)
            }
        })()
    }, [account, stakedBalance, setStakedBalance])

    useEffect(() => {
        if (!account || poolRewards !== undefined) {
            return
        }
        ;(async () => {
            try {
                const response = await apiServer.get(`/pool/staking-pool-rewards?owner=${account}`)
                setPoolRewards(parseFloat(formatEther(BigNumber.from(response.data?.rewards ?? '0'))))
            } catch (err) {
                console.log(err)
            }
        })()
    }, [account, poolRewards, setPoolRewards])

    useEffect(() => {
        if (!account || recentRewards !== undefined) {
            return
        }
        ;(async () => {
            try {
                const response = await apiServer.get(`/rewards/recent-reward?address=${account}`)
                setRecentRewards(
                    parseFloat(formatEther(BigNumber.from(response.data?.recentCollectedRewards ?? '0')))
                )
            } catch (err) {
                console.log(err)
            }
        })()
    }, [account, recentRewards, setRecentRewards])

    useEffect(() => {
        if (!account || marketValue !== undefined) {
            return
        }
        ;(async () => {
            try {
                const response = await apiServer.get(`/rewards/current-market-value?address=${account}`)
                setMarketValue(parseFloat(response.data?.currentMarketValue ?? '0'))
            } catch (err) {
                console.log(err)
            }
        })()
    }, [account, setMarketValue, marketValue])

    useEffect(() => {
        depositBoxBalanceMutate(undefined, true)
        selfTaxMutate(undefined, true)
    }, [depositBoxBalanceMutate, selfTaxMutate])

    return <RewardsContext.Provider value={{
        collectedRewards,
        marketValue,
        stakedBalance,
        poolRewards,
        depositBox,
        tokenPrice,
        recentRewards,
        remainingClaims,
        referralHandlerBalance: (referralHandlerBalance ?? 0) + parseFloat(formatEther(selfTax ?? '0')),
        depositBoxBalance: depositBoxBalance ? parseFloat(formatEther(depositBoxBalance)) : undefined,
        claimReferralReward,
        claimDepositBoxReward,
    }}>{children}</RewardsContext.Provider>
}