import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContentWrapper from 'components/Wrapper/ContentWrapper'
import { ReactComponent as StatusIcon } from '@material-icons/svg/svg/arrow_drop_up/outline.svg'
import useDailyASS from 'hooks/useDailyASS'
import useCurrentData from 'hooks/useCurrentData'
import { amountFormatter } from 'utils/formatter'

interface DataComponentProps {
	data: {
		name: string
		value: number | undefined
		hoverText: string
	}
}

export const DataComponent = (props: DataComponentProps) => {
	const { name, value, hoverText } = props.data
	const [isHover, setIsHover] = useState<boolean>(false)
	const { t } = useTranslation()

	return (
		<ContentWrapper className="h-fit px-6 py-4 md:px-4 md:py-3">
			<div className="grid gap-1">
				<div className="flex gap-1">
					<h3 className="title-2">{t(name)}</h3>
					<div
						className="relative flex h-3 w-3 cursor-pointer items-center justify-center rounded border border-primary text-[10px] text-primary hover:border-black hover:text-black"
						onMouseEnter={() => setIsHover(true)}
						onMouseLeave={() => setIsHover(false)}
					>
						i
						<div
							className={`${
								isHover ? 'block' : 'hidden'
							} absolute -bottom-2 left-0 w-[300px] translate-y-full rounded-[10px] bg-primary p-4`}
						>
							<p className="text-xs text-[#333]">{t(hoverText)}</p>
						</div>
					</div>
				</div>
				<p className="text-2">{value !== undefined ? amountFormatter.format(value) : '-'}</p>
			</div>
		</ContentWrapper>
	)
}

const Lists = () => {
	const { t } = useTranslation()
	// const [mutate, setMutate] = useState<boolean>(false)
	const mutate = false
	const dailyASSData = useDailyASS(mutate)
	const { tvl } = useCurrentData()

	const middle = Math.floor((dailyASSData.length + 1) / 2)
	return (
		<div className="mx-auto grid max-w-[1200px] grid-cols-2 gap-6 lg:grid-cols-1">
			<div className="mx-auto w-full max-w-[600px]">
				<h2 className="title font-normal">{t('Yesterday’s AAS')}</h2>
				<ContentWrapper className="px-8 py-6 lg:mt-5 lg:px-4">
					<div className="flex items-center justify-between">
						<h3 className="title-2">{t('AAS Hourly Log')}</h3>
						<div className="text-2 flex items-center gap-0.5 font-bold text-green">
							<StatusIcon className="fill-success h-8 w-8 fill-green" />
							7%
						</div>
					</div>
					<div className="relative mt-6 grid grid-cols-2 gap-24 sm:gap-12">
						{Array(2)
							.fill(0)
							.map((_none, index) => (
								<table className="" key={index}>
									<thead>
										<tr>
											{['Timestamp', 'Price'].map((item, index) => (
												<th className="table-header-2 text-left first:w-full" key={index}>
													{t(item)}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{Array(middle)
											.fill(0)
											.map((item, i) => {
												if (i + middle * index >= dailyASSData.length) {
													return <></>
												}

												const dailyAss = dailyASSData[i + middle * index]
												return (
													<tr key={i + middle * index}>
														<td className="table-body-2 pt-5 text-left md:pt-2.5">{`${String(
															new Date(dailyAss.timestamp * 1000).getHours()
														).padStart(2, '0')}:${String(
															new Date(dailyAss.timestamp * 1000).getTime()
														).padStart(2, '0')}`}</td>
														<td className="table-body-2 pt-5 text-right md:pt-2.5">{`${dailyAss.value}`}</td>
													</tr>
												)
											})}
									</tbody>
								</table>
							))}
						<div className="absolute left-1/2 top-0 h-full w-px bg-primary" />
					</div>
				</ContentWrapper>
			</div>
			<div className="mx-auto mt-[20vh] flex w-full max-w-[600px] flex-col gap-3 lg:order-[-1] lg:mt-0 md:gap-2">
				<DataComponent
					data={{
						name: 'Current TWAP',
						value: 12345,
						hoverText: 'Time Weighted Average Price',
					}}
				/>
				<DataComponent
					data={{
						name: 'TVL',
						value: tvl,
						hoverText: 'Total Value Deposited / Total Value Locked',
					}}
				/>
			</div>
		</div>
	)
}

export default Lists
