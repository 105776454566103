import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Layout from 'layouts/Layout'
import ContainerWrapper from 'components/Wrapper/ContainerWrapper'
import Button from 'components/Button'
import Membership from './Membership'
import Gallery from './Gallery'

const NFT = () => {
	const [status, setStatus] = useState<number>(0)
	const { t } = useTranslation()

	return (
		<Layout title="QUOTA Webapp - NFT">
			<ContainerWrapper title={t('NFT')}>
				<div className="-mt-[60px] flex justify-center gap-8 lg:-mt-10 md:-mt-0">
					<Button
						variant="outlined"
						size="large"
						isActive={status === 0}
						onClick={() => setStatus(0)}
					>
						{t('Membership')}
					</Button>
					<Button
						variant="outlined"
						size="large"
						isActive={status === 1}
						onClick={() => setStatus(1)}
					>
						{t('Gallery')}
					</Button>
				</div>
				<div className="mt-8 md:mt-6">
					{status === 0 ? <Membership goToGallery={() => setStatus(1)} /> : <Gallery />}
				</div>
			</ContainerWrapper>
		</Layout>
	)
}

export default NFT
