import create from 'zustand'
import { persist, devtools } from 'zustand/middleware'

type WalletConnectModalState = {
	isOpen: boolean
}

interface StateWithMutation extends WalletConnectModalState {
	setIsOpen: (value: boolean) => void
}

const useWalletConnectModal = create<StateWithMutation>()(
	devtools(
		persist(
			(set) => ({
				isOpen: false,
				setIsOpen: (payload) => {
					set((state) => ({
						...state,
						isOpen: payload,
					}))
				},
			}),
			{
				name: 'bear-storage',
			}
		)
	)
)

export default useWalletConnectModal
