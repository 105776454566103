import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const TokenBuyButton = () => {
	const [isHover, setIsHover] = useState<boolean>(false)
	const { t } = useTranslation()

	return (
		<a
			href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x45F73dF56baBE3681447Da85A57Ab40289ec03C6"
			rel="noreferrer"
			target="_blank"
		>
			<button
				className="relative	h-12 w-[168px] rounded-md from-maroon to-orange p-0.5 bg-gradient-256"
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				<div className="flex h-full items-center justify-center gap-1.5 rounded-md bg-background">
					<img src="/images/quota-coin.png" alt="Coin" className="h-7 w-7" />
					<span className="text-xl font-bold text-primary drop-shadow">
						<span className="text-orange">Buy</span> 4.0V2
					</span>
					{isHover && (
						<div className="absolute right-0 top-14 z-[1000] flex rounded-[10px] bg-[#FFFFFFB5] p-4 shadow-[inset_0px_3px_6px_#00000058] backdrop-blur-lg">
							<p
								className="whitespace-nowrap text-xs text-gray-4"
								dangerouslySetInnerHTML={{
									__html: t('Make sure to select “<b>Market(V2)</b>” for swap'),
								}}
							/>
						</div>
					)}
				</div>
			</button>
		</a>
	)
}

export default TokenBuyButton
