import { useCallback, useMemo } from 'react'
import { useEthers } from '@usedapp/core'
import { ethers } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'

import { LiquidityExtensionAbi } from 'config/abis'
import { callContract } from 'utils/contracts/callContract'
import { contracts } from 'config/contracts'
import { JsonRpcProvider } from '@ethersproject/providers'

const useLiquidityExtension = () => {
	const { library } = useEthers()
	// const {account} = useEthers()
	const account = '0x1f15a7340D1D833dE4cfd457173B83317De81FED'

	const routerContract = useMemo(
		() =>
			new ethers.Contract(
				contracts.LiquidityExtension,
				LiquidityExtensionAbi,
				library instanceof JsonRpcProvider ? library.getSigner() : library
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[library]
	)

	const addLiquidity = useCallback(
		async (token: string, quotaAmount: number, tokenAmount: number, tokenDecimals: number) => {
			const opts = {}
			return await callContract(
				routerContract,
				'addLiquidity',
				[
					contracts.ETF,
					token,
					parseUnits(quotaAmount.toString(), 18),
					parseUnits(tokenAmount.toString(), tokenDecimals),
					0,
					0,
				],
				opts
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[routerContract, account]
	)

	const addLiquidityETH = useCallback(
		async (quotaAmount: number, tokenAmount: number) => {
			const opts = { value: parseUnits(tokenAmount.toString(), 18) }

			return await callContract(
				routerContract,
				'addLiquidityETH',
				[contracts.ETF, parseUnits(quotaAmount.toString(), 18), 0, 0],
				opts
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[routerContract, account]
	)

	return {
		addLiquidity,
		addLiquidityETH,
	}
}

export default useLiquidityExtension
