import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ReactComponent as CopyIcon } from '@material-icons/svg/svg/content_copy/outline.svg'
import { contracts } from 'config/contracts'

interface Props {
	className?: string
}

const TokenAddressButton = (props: Props) => {
	const { className = '' } = props
	const { t } = useTranslation()

	const handleClick = () => {
		navigator.clipboard.writeText(contracts.ETF)
		toast.success('Successfully copied to clipboard')
	}

	return (
		<button
			className={`relative	h-12 w-36 rounded-md from-maroon to-orange p-0.5 bg-gradient-256 ${className}`}
			title="Click to copy token address"
			onClick={handleClick}
		>
			<div className="flex h-full items-center justify-center gap-1 rounded-md bg-background">
				<span className="text-xs font-medium text-primary underline">{t('Token Address')}</span>
				<CopyIcon className="h-4 w-4 fill-primary" />
			</div>
		</button>
	)
}

export default TokenAddressButton
