import { useLayoutEffect, useState } from 'react'

interface WindowSizeType {
	width?: number
	height?: number
}

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState<WindowSizeType>({
		width: undefined,
		height: undefined,
	})

	const handleSize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		})
	}

	useLayoutEffect(() => {
		handleSize()

		window.addEventListener('resize', handleSize)

		return () => window.removeEventListener('resize', handleSize)
	}, [])

	return windowSize
}

export default useWindowSize
