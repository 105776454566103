import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ImportantIcon } from '@material-icons/svg/svg/error/outline.svg'
import useERC20Token from 'hooks/useERC20Token'
import { contracts } from 'config/contracts'
import { amountFormatter, dollarFormatter } from 'utils/formatter'
import useASSCountDown from 'hooks/useASSCountDown'
import Divider from './Divider'
import {CommonContext} from "../contexts/common";
import {RewardsContext} from "../contexts/rewards";

interface Props {
	variant?: string
}

const MyStats = (props: Props) => {
	const timerRef = useRef<NodeJS.Timer>()
	const [countDownStr, setCountDownStr] = useState<string>('00 : 00 : 00')
	const { variant = 'normal' } = props
	const [isHover, setIsHover] = useState<boolean>(false)
	const { t } = useTranslation()
	const { tokenPrice } = useContext(CommonContext)
	const { tokenBalance } = useERC20Token(contracts.ETF)
	const { stakedBalance, poolRewards, referralHandlerBalance, depositBoxBalance } = useContext(RewardsContext)
	const { lastASS } = useContext(CommonContext)
	const countDown = useASSCountDown()

	const updateTime = useCallback(() => {
		if (!countDown) {
			return
		}

		const timestamp = Math.floor(new Date().getTime() / 1000)
		const delta = countDown - timestamp
		if (delta < 0) {
			return
		}

		const newDurationString =
			Math.floor(delta / 3600)
				.toString()
				.padStart(2, '0') +
			':' +
			Math.floor((delta / 60) % 60)
				.toString()
				.padStart(2, '0') +
			':' +
			Math.floor(delta % 60)
				.toString()
				.padStart(2, '0')

		setCountDownStr(newDurationString)
	}, [countDown])

	useEffect(() => {
		if (countDown) {
			timerRef.current = setInterval(updateTime, 1000)
		}

		return () => clearInterval(timerRef.current)
	}, [countDown, updateTime])

	return (
		<div
			className={`relative cursor-default rounded-full border-2 border-orange ${
				variant === 'normal' ? 'px-6 py-2.5' : 'px-4 py-1.5'
			}`}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<div
				className={`flex items-start gap-1.5 ${variant === 'normal' ? 'text-base' : 'text-[10px]'}`}
			>
				<ImportantIcon className={`fill-primary ${variant === 'normal' ? '' : 'h-4 w-4'}`} />
				<span className="text-primary">{t('My Stats')}</span>
			</div>
			<div className={!isHover ? 'hidden' : 'block'}>
				<div
					className={`absolute -bottom-1 right-0 z-[100] translate-y-full ${
						isHover ? 'opacity-100' : 'opacity-0'
					}`}
				>
					<div className={`rounded-[22px] from-orange to-maroon p-[3px] bg-gradient-221`}>
						<div className="flex w-[232px] flex-col rounded-[22px] bg-background px-10 py-9">
							<div className="grid gap-6">
								<div className="grid gap-2">
									<span className="text-sm font-medium text-gray-3">{t('Token Price')}</span>
									<div className="flex items-center justify-between">
										<span className="text-xs font-bold text-primary">
											{tokenPrice !== undefined ? dollarFormatter.format(tokenPrice) : '-'}
										</span>
										{/* <div className="flex items-center gap-0.5 text-xs font-bold text-green">
											<StatusIcon className="fill-success h-5 w-5 fill-green" />
											2.50%
										</div> */}
									</div>
									<Divider />
								</div>
								<div className="grid gap-2">
									<span className="text-sm font-medium text-gray-3">{t('AAS Countdown')}</span>
									<div className="flex items-center justify-between">
										<span className="text-xs font-bold text-primary">{countDownStr}</span>
									</div>
									<Divider />
								</div>
								<div className="grid gap-2">
									<span className="text-sm font-medium text-gray-3">{t('Yesterday’s AAS')}</span>
									<div className="flex items-center justify-between">
										<span className="text-xs font-bold text-primary">
											{(
												(lastASS?.value || 0) * (lastASS?.direction === 'NEGATIVE' ? -1 : 1)
											).toFixed(2)}
											%
										</span>
										{/* <div className="flex items-center gap-0.5 text-xs font-bold text-green">
											<StatusIcon className="fill-success h-5 w-5 fill-green" />
											2.50%
										</div> */}
									</div>
									<Divider />
								</div>
								<div className="grid gap-2">
									<span className="text-sm font-medium text-gray-3">{t('Token Balance')}</span>
									<div className="flex items-center justify-between">
										<span className="text-xs font-bold text-primary">
											{tokenBalance !== undefined ? amountFormatter.format(tokenBalance) : '-'}
										</span>
									</div>
									<Divider />
								</div>
								<div className="grid gap-2">
									<span className="text-sm font-medium text-gray-3">{t('Staked Balance')}</span>
									<div className="flex items-center justify-between">
										<span className="text-xs font-bold text-primary">
											{stakedBalance !== undefined ? amountFormatter.format(stakedBalance) : '-'}
										</span>
									</div>
									<Divider />
								</div>
								<div className="grid gap-2">
									<span className="text-sm font-medium text-gray-3">{t('Unclaimed Balance')}</span>
									<div className="flex items-center justify-between">
										<span className="text-xs font-bold text-primary">
											{amountFormatter.format(
												(poolRewards ?? 0) +
													(referralHandlerBalance ?? 0) +
													(depositBoxBalance ?? 0)
											)}
										</span>
									</div>
									<Divider />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MyStats
