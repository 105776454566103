import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shortenAddress } from '@usedapp/core'

import Layout from 'layouts/Layout'
import ContainerWrapper from 'components/Wrapper/ContainerWrapper'
import ContentWrapper from 'components/Wrapper/ContentWrapper'
import Button from 'components/Button'
import NFTWrapper from 'components/Wrapper/NFTWrapper'

const buttons = [
	{ name: 'Black', color: '#000' },
	{ name: 'Diamond', color: '#A3A7AF' },
	{ name: 'Gold', color: '#D6B545' },
	{ name: 'Silver', color: '#8B8B89' },
	{ name: 'Bronze', color: '#CD5E0B' },
]

const topNFTs = [
	{
		username: '0x3BddD54bfe95481ae19b05805F6f7dCfB46fB024',
		newReferrals: 16,
		stakedAmount: 3.088,
	},
	{
		username: '0x3BddD54bfe95481ae19b05805F6f7dCfB46fB024',
		newReferrals: 16,
		stakedAmount: 3.088,
	},
	{
		username: '0x3BddD54bfe95481ae19b05805F6f7dCfB46fB024',
		newReferrals: 16,
		stakedAmount: 3.088,
	},
]

const Leaderboard = () => {
	const [activeButton, setActiveButton] = useState<number>(0)
	const { t } = useTranslation()

	return (
		<Layout title="QUOTA Webapp - Leaderboard">
			<ContainerWrapper title="Leaderboard">
				<h2 className="subtitle">{t('Weekly Leaderboard per Rank')}</h2>
				<div className="mt-6 flex flex-wrap gap-x-4 gap-y-2 sm:justify-center">
					{buttons.map((button, index) => (
						<Button
							variant="outlined"
							size="small"
							isActive={activeButton === index}
							onClick={() => setActiveButton(index)}
							key={index}
						>
							{button.name}
						</Button>
					))}
				</div>
				<div className="mt-6 flex gap-10 xl:flex-col sm:mt-4">
					<ContentWrapper className="w-full p-10 sm:px-4 sm:py-6">
						<div className="flex gap-8 sm:flex-col sm:items-center">
							<NFTWrapper
								imgURL={`/images/nfts/${buttons[activeButton].name.toLowerCase()}.png`}
								text={buttons[activeButton].name}
								color={buttons[activeButton].color}
							/>
							<div className="flex w-full flex-col justify-between sm:gap-1 xs:hidden">
								{topNFTs.map((nft, index) => (
									<Fragment key={index}>
										<p className="subtitle-2">#TOP {index + 1}</p>
										<div className="flex items-center justify-between">
											<p className="text-3 font-semibold">Username</p>
											<p className="text-3">{shortenAddress(nft.username)}</p>
										</div>
										<div className="flex items-center justify-between">
											<p className="text-3 font-semibold">New Referrals(This Week)</p>
											<p className="text-3">{nft.newReferrals}</p>
										</div>
										<div className="flex items-center justify-between">
											<p className="text-3 font-semibold">Staked Amount</p>
											<div className="flex items-center gap-1">
												<p className="text-3">{nft.stakedAmount}</p>
												<img src="/images/quota-coin.png" alt="" className="h-5 w-5" />
											</div>
										</div>
										<div className="h-px w-full bg-primary" />
									</Fragment>
								))}
							</div>
							<div className="hidden w-full xs:block">
								<table className="w-full">
									<thead>
										<tr className="text-[10px] uppercase text-primary">
											<th>#</th>
											<th>Username</th>
											<th>New Referrals</th>
											<th>Staked Amount</th>
										</tr>
									</thead>
									<tbody>
										{topNFTs.map((nft, index) => (
											<tr className="text-center text-[10px] text-primary" key={index}>
												<td className="pt-2">Top {index + 1}</td>
												<td className="pt-2">{shortenAddress(nft.username)}</td>
												<td className="pt-2">{nft.newReferrals}</td>
												<td className="flex items-center justify-center gap-1 pt-2">
													{nft.stakedAmount}
													<img src="/images/quota-coin.png" alt="" className="h-4 w-4" />
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</ContentWrapper>
					<div className="flex w-full max-w-[430px] flex-col justify-between xl:grid xl:max-w-none xl:grid-cols-2 xl:gap-10 lg:grid-cols-1 sm:gap-6">
						<div>
							<h2 className="subtitle">{t('Weekly Prize Amount')}</h2>
							<ContentWrapper className="mt-6 px-10 py-8 sm:mt-3 sm:py-4 sm:px-8">
								<p className="text-[80px] text-orange sm:text-[40px]">100</p>
								<p className="text-3 font-medium">= $30,000 USD Value (Market Price)</p>
							</ContentWrapper>
						</div>
						<div>
							<h2 className="subtitle">{t('Countdown for LB Prizes')}</h2>
							<ContentWrapper className="mt-6 px-10 py-8 sm:mt-3 sm:py-4 sm:px-8">
								<p className="text-3 font-medium">Upcoming prizes in</p>
								<div className="mt-4 w-fit text-[80px] leading-none text-orange sm:mt-2 sm:text-[40px]">
									18 : 18 : 18
									<div className="text-3 mx-5 flex justify-between text-primary sm:mx-0">
										<p>hours</p>
										<p>mins</p>
										<p>secs</p>
									</div>
								</div>
							</ContentWrapper>
						</div>
					</div>
				</div>
			</ContainerWrapper>
		</Layout>
	)
}

export default Leaderboard
