import { useEffect, useCallback, useMemo, useState } from 'react'
import { useEthers } from '@usedapp/core'
import { BigNumber, constants, ethers } from 'ethers'
import { formatEther, formatUnits, parseEther } from 'ethers/lib/utils'
import useSWR from 'swr'

import { useProvider } from './useProvider'
import { PancakePairAbi, PerpetualTokenRewardsAbi } from 'config/abis'
import { contractFetcher } from 'utils/contracts/contractFetcher'
import { callContract } from 'utils/contracts/callContract'
import { apiServer } from 'utils/apiServer'

const useStakingPool = (pairAddress: string, stakingAddress: string, mutate: boolean = false) => {
	const library = useProvider()
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'
	const [apy, setApy] = useState<number>()

	const { data: pairTokenBalance, mutate: pairTokenBalanceMutate } = useSWR(
		['PairToken.balanceOf', pairAddress, 'balanceOf', account],
		{
			fetcher: contractFetcher(library, PancakePairAbi),
		}
	)

	const { data: onlyWhitelisted, mutate: onlyWhitelistedMutate } = useSWR(
		['PerpetualTokenRewards.onlyWhitelisted', stakingAddress, 'onlyWhitelisted'],
		{
			fetcher: contractFetcher(library, PerpetualTokenRewardsAbi),
		}
	)

	const { data: isWhitelisted, mutate: isWhitelistedMutate } = useSWR(
		['PerpetualTokenRewards.isWhitelisted', stakingAddress, 'isWhitelisted', account],
		{
			fetcher: contractFetcher(library, PerpetualTokenRewardsAbi),
		}
	)

	const { data: stakedTokenBalance, mutate: stakedTokenBalanceMutate } = useSWR(
		['PerpetualTokenRewards.balanceOf', stakingAddress, 'balanceOf', account],
		{
			fetcher: contractFetcher(library, PerpetualTokenRewardsAbi),
		}
	)

	const { data: rewardTokenBalance, mutate: rewardTokenBalanceMutate } = useSWR(
		['PerpetualTokenRewards.rewards', stakingAddress, 'earnedTokens', account],
		{
			fetcher: contractFetcher(library, PerpetualTokenRewardsAbi),
		}
	)

	const { data: unlocksAt, mutate: unlocksAtMutate } = useSWR(
		['PerpetualTokenRewards.unlocksAt', stakingAddress, 'unlocksAt', account],
		{
			fetcher: contractFetcher(library, PerpetualTokenRewardsAbi),
		}
	)

	const pairContract = useMemo(
		() => new ethers.Contract(pairAddress, PancakePairAbi, library.getSigner()),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[library]
	)
	const stakingContract = useMemo(
		() => new ethers.Contract(stakingAddress, PerpetualTokenRewardsAbi, library.getSigner()),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[library]
	)

	const approve = useCallback(async () => {
		const opts = {}
		return await callContract(pairContract, 'approve', [stakingAddress, constants.MaxUint256], opts)
	}, [pairContract, stakingAddress])

	const stake = useCallback(
		async (amount: number, period: number) => {
			const opts = {}
			let amountWei = parseEther(amount.toString())
			if (amountWei.gt(pairTokenBalance)) {
				amountWei = pairTokenBalance
			}
			return await callContract(stakingContract, 'stake', [amountWei, period], opts)
		},
		[stakingContract, pairTokenBalance]
	)

	const unstake = useCallback(
		async (amount: number) => {
			const opts = {}
			let amountWei = parseEther(amount.toString())
			if (amountWei.gt(stakedTokenBalance)) {
				amountWei = stakedTokenBalance
			}
			return await callContract(stakingContract, 'withdraw', [amountWei], opts)
		},
		[stakingContract, stakedTokenBalance]
	)

	const getReward = useCallback(async () => {
		const opts = {}
		return await callContract(stakingContract, 'getReward', [], opts)
	}, [stakingContract])

	const allowance = useCallback(async () => {
		return parseFloat(
			formatEther(await callContract(pairContract, 'allowance', [account, stakingAddress]))
		)
	}, [account, pairContract, stakingAddress])

	useEffect(() => {
		;(async () => {
			try {
				const response = await apiServer.get(`/pool/apy?stakingAddress=${stakingAddress}`)
				setApy(parseFloat(formatUnits(response.data?.apy ?? '0', 6)))
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, stakingAddress])

	useEffect(() => {
		pairTokenBalanceMutate(undefined, true)
		stakedTokenBalanceMutate(undefined, true)
		rewardTokenBalanceMutate(undefined, true)
		isWhitelistedMutate(undefined, true)
		onlyWhitelistedMutate(undefined, true)
		unlocksAtMutate(undefined, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		account,
		mutate,
		pairTokenBalanceMutate,
		stakedTokenBalanceMutate,
		rewardTokenBalanceMutate,
		isWhitelistedMutate,
		onlyWhitelistedMutate,
		unlocksAtMutate,
	])

	return {
		apy,
		onlyWhitelisted,
		isWhitelisted,
		unlocksAt: (unlocksAt as BigNumber | undefined)?.toNumber(),
		pairTokenBalance: pairTokenBalance ? parseFloat(formatEther(pairTokenBalance)) : undefined,
		stakedTokenBalance: stakedTokenBalance
			? parseFloat(formatEther(stakedTokenBalance))
			: undefined,
		rewardTokenBalance: rewardTokenBalance
			? parseFloat(formatEther(rewardTokenBalance))
			: undefined,
		approve,
		allowance,
		stake,
		unstake,
		getReward,
	}
}

export default useStakingPool
