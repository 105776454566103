import { useEffect, useCallback, useMemo } from 'react'
import { useEthers } from '@usedapp/core'
import { constants, ethers } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import useSWR from 'swr'

import { useProvider } from './useProvider'
import { ERC20Abi } from 'config/abis'
import { contractFetcher } from 'utils/contracts/contractFetcher'
import { callContract } from 'utils/contracts/callContract'

const useERC20Token = (address: string | undefined, mutate: boolean = false) => {
	const library = useProvider()
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'

	const { data: tokenBalance, mutate: tokenBalanceMutate } = useSWR(
		['ERC20.balanceOf', address, 'balanceOf', account],
		{
			fetcher: contractFetcher(library, ERC20Abi),
		}
	)

	const { data: nativeTokenBalance, mutate: nativeTokenBalanceMutate } = useSWR(
		['Provider.getBalance', 'getBalance', account],
		{
			fetcher: contractFetcher(library, null),
		}
	)

	const { data: decimals } = useSWR(['ERC20.decimals', address, 'decimals'], {
		fetcher: contractFetcher(library, ERC20Abi),
	})

	const contract = useMemo(
		() =>
			address && address !== constants.AddressZero
				? new ethers.Contract(address, ERC20Abi, library.getSigner())
				: undefined,
		[library, address]
	)

	const approve = useCallback(
		async (to: string) => {
			const opts = {}
			return contract
				? await callContract(contract, 'approve', [to, constants.MaxUint256], opts)
				: () => undefined
		},
		[contract]
	)

	const allowance = useCallback(
		async (to: string) => {
			return contract
				? parseFloat(
						formatUnits(await callContract(contract, 'allowance', [account, to]), decimals ?? 18)
				  )
				: address === constants.AddressZero
				? nativeTokenBalance
				: undefined
		},
		[contract, decimals, account, nativeTokenBalance, address]
	)

	const balanceOf = useCallback(
		async (contractAddress: string, to: string) => {

			const contract = new ethers.Contract(contractAddress, ERC20Abi, library)
			const decimals = await contract.decimals()
			return parseFloat(
				formatUnits(await callContract(contract, 'balanceOf', [to]), decimals ?? 18)
			)
		},
		[library]
	)

	useEffect(() => {
		tokenBalanceMutate(undefined, true)
		nativeTokenBalanceMutate(undefined, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, mutate, tokenBalanceMutate, nativeTokenBalanceMutate])

	return {
		decimals: address === constants.AddressZero ? 18 : decimals,
		tokenBalance:
			address === constants.AddressZero
				? nativeTokenBalance
					? parseFloat(formatUnits(nativeTokenBalance, 18))
					: undefined
				: tokenBalance
				? parseFloat(formatUnits(tokenBalance, decimals ?? 18))
				: undefined,
		approve,
		allowance,
		balanceOf,
	}
}

export default useERC20Token
