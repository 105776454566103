import { useState, useEffect } from 'react'
import { apiServer } from 'utils/apiServer'

const useMarketCaps = (type: ChartType = 'week', mutate: boolean = false) => {
	const [marketCaps, setMarketCaps] = useState<ChartDataType[]>([])

	useEffect(() => {
		;(async () => {
			try {
				const response = await apiServer.get(`/chart/market_cap?type=${type}`)
				setMarketCaps(
					(response.data ?? []).map((item: any) => {
						return {
							value: parseFloat(item?.value ?? '0'),
							timestamp: item?.timestamp,
						} as ChartDataType
					})
				)
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, type])

	return marketCaps
}

export default useMarketCaps
