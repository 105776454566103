import { useState, useEffect } from 'react'
import { apiServer } from 'utils/apiServer'
import { formatEther } from 'ethers/lib/utils'

const useTotalSupply = (type: ChartType = 'week', mutate: boolean = false) => {
	const [totalSupply, setTotalSupply] = useState<ChartDataType[]>([])

	useEffect(() => {
		;(async () => {
			try {
				const response = await apiServer.get(`/chart/total_supply?type=${type}`)
				setTotalSupply(
					(response.data ?? []).map((item: any) => {
						return {
							value: parseFloat(formatEther(item?.value ?? '0')),
							timestamp: item?.timestamp,
						} as ChartDataType
					})
				)
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, type])

	return totalSupply
}

export default useTotalSupply
