import { Provider } from '@ethersproject/providers'
import { BigNumber, BigNumberish, Contract } from 'ethers'

export async function setGasPrice(txnOpts: any, provider: Provider) {
	const gasPrice = await provider.getGasPrice()

	const feeData = await provider.getFeeData()

	// the wallet provider might not return maxPriorityFeePerGas in feeData
	// in which case we should fallback to the usual getGasPrice flow handled below
	if (feeData && feeData.maxPriorityFeePerGas) {
		txnOpts.maxFeePerGas = gasPrice.mul(110).div(100)
		txnOpts.maxPriorityFeePerGas = feeData.maxPriorityFeePerGas
		return
	}

	return
}

export async function getGasLimit(
	contract: Contract,
	method: string,
	params = [],
	value: BigNumberish
) {
	const defaultValue = BigNumber.from(0)

	if (!value) {
		value = defaultValue
	}

	let gasLimit = await contract.estimateGas[method](...params, { value })

	if (gasLimit.lt(22000)) {
		gasLimit = BigNumber.from(22000)!
	}

	console.log('gas limit', gasLimit.toString())
	return gasLimit.mul(11000).div(10000) // add a 10% buffer
}
