import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
	BSC,
	CoinbaseWalletConnector,
	Config,
	DAppProvider,
	MetamaskConnector,
} from '@usedapp/core'
import { WalletConnectConnector } from '@usedapp/wallet-connect-connector'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Router from 'routes'
import 'styles/app.css'
import { getDefaultProvider } from 'ethers'
import { rpcUrl } from 'utils/constants'
import {CommonContextProvider} from "./contexts/common";
import {RewardsContextProvider} from "./contexts/rewards";

const config: Config = {
	readOnlyChainId: BSC.chainId,
	readOnlyUrls: {
		[BSC.chainId]: getDefaultProvider(rpcUrl),
	},
	connectors: {
		metamask: new MetamaskConnector(),
		coinbase: new CoinbaseWalletConnector(),
		walletConnect: new WalletConnectConnector({
			rpc: {
				[BSC.chainId]: rpcUrl,
			},
		}),
	},
}

const App = () => {
	const { i18n } = useTranslation()

	useEffect(() => {
		if (i18n.language === 'ko') {
			document.getElementsByTagName('html')[0].className = 'korean-class'
		} else {
			document.getElementsByTagName('html')[0].className = 'english-class'
		}
	}, [i18n.language])

	return (
		<>

			<DAppProvider config={config}>
				<CommonContextProvider>
					<RewardsContextProvider>
						<div className="App">
							<Router />
						</div>
					</RewardsContextProvider>
				</CommonContextProvider>
			</DAppProvider>
			<ToastContainer autoClose={1000} hideProgressBar />
		</>
	)
}

export default App
