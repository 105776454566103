import create from 'zustand'
import { devtools } from 'zustand/middleware'

type AppStatusState = {
	tokenPrice: number | undefined
	countDown: number | undefined
	yesterdayASS: number | undefined
	stakedBalance: number | undefined
	unclaimedBalance: number | undefined

	tvl: number | undefined
	totalSupply: number | undefined
	circulatingSupply: number | undefined
	marketValue: number | undefined

	collectedRewards: number | undefined
	poolRewards: number | undefined
	recentRewards: number | undefined

	nodeAnalyze: NFTNodeAnalyze | undefined
	node: NFTNode | undefined
}

interface StateWithMutation extends AppStatusState {
	setTokenPrice: (value: number) => void
	setCountDown: (value: number) => void
	setYesterdayASS: (value: number) => void
	setStakedBalance: (value: number) => void
	setUnclaimedBalance: (value: number) => void

	setTvl: (value: number) => void
	setTotalSupply: (value: number) => void
	setCirculatingSupply: (value: number) => void
	setMarketValue: (value: number) => void

	setCollectedRewards: (value: number) => void
	setPoolRewards: (value: number) => void
	setRecentRewards: (value: number) => void

	setNodeAnalyze: (value: NFTNodeAnalyze) => void
	setNode: (value: NFTNode) => void
}

const useAppStatus = create<StateWithMutation>()(
	devtools(
		// persist(
		(set) => ({
			tokenPrice: undefined,
			countDown: undefined,
			yesterdayASS: undefined,
			stakedBalance: undefined,
			unclaimedBalance: undefined,
			tvl: undefined,

			totalSupply: undefined,
			circulatingSupply: undefined,
			marketValue: undefined,

			collectedRewards: undefined,
			poolRewards: undefined,
			recentRewards: undefined,

			nodeAnalyze: undefined,
			node: undefined,

			setTokenPrice: (payload) => {
				set((state) => ({
					...state,
					tokenPrice: payload,
				}))
			},
			setCountDown: (payload) => {
				set((state) => ({
					...state,
					countDown: payload,
				}))
			},
			setYesterdayASS: (payload) => {
				set((state) => ({
					...state,
					yesterdayASS: payload,
				}))
			},
			setStakedBalance: (payload) => {
				set((state) => ({
					...state,
					stakedBalance: payload,
				}))
			},
			setUnclaimedBalance: (payload) => {
				set((state) => ({
					...state,
					unclaimedBalance: payload,
				}))
			},
			setTvl: (payload) => {
				set((state) => ({
					...state,
					tvl: payload,
				}))
			},

			setTotalSupply: (payload) => {
				set((state) => ({
					...state,
					totalSupply: payload,
				}))
			},
			setCirculatingSupply: (payload) => {
				set((state) => ({
					...state,
					circulatingSupply: payload,
				}))
			},
			setMarketValue: (payload) => {
				set((state) => ({
					...state,
					marketValue: payload,
				}))
			},

			setCollectedRewards: (payload) => {
				set((state) => ({
					...state,
					collectedRewards: payload,
				}))
			},
			setPoolRewards: (payload) => {
				set((state) => ({
					...state,
					poolRewards: payload,
				}))
			},
			setRecentRewards: (payload) => {
				set((state) => ({
					...state,
					recentRewards: payload,
				}))
			},

			setNodeAnalyze: (payload) => {
				set((state) => ({
					...state,
					nodeAnalyze: payload,
				}))
			},
			setNode: (payload) => {
				set((state) => ({
					...state,
					node: payload,
				}))
			},
		}),
		{
			name: 'bear-storage',
		}
	)
	// )
)

export default useAppStatus
