import { BigNumber, BigNumberish, Contract } from 'ethers'
import { extractError, NOT_ENOUGH_FUNDS, RPC_ERROR, USER_DENIED } from './transactionErrors'
import { getGasLimit, setGasPrice } from './utils'

export async function callContract(
	contract: Contract,
	method: string,
	params: any,
	opts?: {
		value?: BigNumber | number
		gasLimit?: BigNumber | number
		sentMsg?: string
		successMsg?: string
		hideSuccessMsg?: boolean
		failMsg?: string
		setPendingTxns?: (txns: any) => void
	}
) {
	try {
		if (!Array.isArray(params) && typeof params === 'object' && opts === undefined) {
			opts = params
			params = []
		}

		if (!opts) {
			opts = {}
		}

		const txnOpts: any = {}

		if (opts.value) {
			txnOpts.value = opts.value
		}

		txnOpts.gasLimit = opts.gasLimit
			? opts.gasLimit
			: await getGasLimit(contract, method, params, opts.value as BigNumberish)

		await setGasPrice(txnOpts, contract.provider)

		const res = await contract[method](...params, txnOpts)

		return res
	} catch (e: any) {
		const [message, type] = extractError(e)
		switch (type) {
			case NOT_ENOUGH_FUNDS:
				throw new Error(
					'There is not enough ETH in your account on Arbitrum to send this transaction.'
				)
			case USER_DENIED:
				throw new Error('Transaction was cancelled.')
			case RPC_ERROR:
				throw new Error('Transaction failed due to RPC error.')
			default:
				throw new Error(message)
		}
	}
}
