import ContentWrapper from 'components/Wrapper/ContentWrapper'

const tokens = [
	{
		name: 'WETH',
		unit: 'eth',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'MATIC',
		unit: 'matic',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'LINK',
		unit: 'link',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'UNI',
		unit: 'uni',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'GEN',
		unit: 'gen',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'WBTC',
		unit: 'btc',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'USDT',
		unit: 'USDT',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'USDC',
		unit: 'USDC',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
]

interface TokenComponentProps {
	token: {
		name: string
		unit: string
		APY: number
		currentRewards: number
		stakedLPTokens: number
		LPTokenBalance: number
	}
}

export const TokenComponent = (props: TokenComponentProps) => {
	const { name, unit, APY, currentRewards, stakedLPTokens, LPTokenBalance } = props.token

	return (
		<ContentWrapper className="relative p-6 text-gray-dark">
			<img
				src="/images/quota-coin.png"
				alt=""
				className="absolute top-1/2 left-1/2 -z-10 h-64 w-64 -translate-x-1/2 -translate-y-1/2 opacity-10"
			/>
			<div className="flex items-center justify-between">
				<div className="flex items-end">
					<img
						src={`/images/coin/${unit}.png`}
						alt=""
						className="z-10 -mr-6 h-24 w-24 rounded-full"
					/>
					<img src="/images/quota-coin.png" alt="" className="h-20 w-20" />
				</div>
				<p className="text-2xl font-bold lg:text-xl md:text-lg">{APY}% APY</p>
			</div>
			<div className="mt-5 flex justify-between text-3xl font-bold xl:text-2xl lg:text-lg md:text-base">
				<p>{name} - 4.0</p>
				<p className="md:text-sm">Time Left: 00:00:00</p>
			</div>
			<div className="mt-16 flex items-center justify-between text-center xl:mt-8 xl:flex-col xl:gap-2">
				<div className="flex w-[30%] flex-col items-center gap-5 font-semibold xl:w-full xl:items-start xl:gap-2">
					<p className="lg:text-base">Current Rewards</p>
					<div className="flex w-full flex-col items-center gap-5 xl:flex-row xl:justify-between">
						<p className="text-2xl lg:text-lg">{currentRewards}</p>
						<button className="btn">Collect</button>
					</div>
				</div>
				<div className="h-24 w-px bg-gray-5 xl:h-px xl:w-full" />
				<div className="flex w-[30%] flex-col items-center gap-5 font-semibold xl:w-full xl:items-start xl:gap-2">
					<p className="lg:text-base">Staked LP Tokens</p>
					<div className="flex w-full flex-col items-center gap-5 xl:flex-row xl:justify-between">
						<p className="text-2xl lg:text-lg">{stakedLPTokens}</p>
						<button className="btn">Unstake</button>
					</div>
				</div>
				<div className="h-24 w-px bg-gray-5 xl:h-px xl:w-full" />
				<div className="flex w-[30%] flex-col items-center gap-5 font-semibold xl:w-full xl:items-start xl:gap-2">
					<p className="lg:text-base">LP Token Balance</p>
					<div className="flex w-full flex-col items-center gap-5 xl:flex-row xl:justify-between">
						<p className="text-2xl lg:text-lg">{LPTokenBalance}</p>
						<button className="btn">Approve</button>
					</div>
				</div>
			</div>
		</ContentWrapper>
	)
}

const InitialDistribution = () => {
	return (
		<div className="grid grid-cols-2 gap-10 lg:gap-5 sm:grid-cols-1">
			{tokens.map((token, index) => (
				<TokenComponent token={token} key={index} />
			))}
		</div>
	)
}

export default InitialDistribution
