import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as RightIcon } from '@material-icons/svg/svg/chevron_right/outline.svg'
import { ReactComponent as ExternalIcon } from '@material-icons/svg/svg/open_in_new/outline.svg'

const GoToLandingPageButton = () => {
	const [isHover, setIsHover] = useState<boolean>(false)
	const { t } = useTranslation()

	return (
		<a href="https://quota.network" rel="noreferrer" target="_blank">
			<div
				className={`relative h-[26px] w-[176px] cursor-pointer rounded-full ${
					!isHover ? 'bg-transparent' : 'bg-[#FFFFFF5C]'
				}`}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				<div
					className={`absolute top-0 flex h-[26px] w-[26px] items-center justify-center rounded-full ${
						!isHover ? 'left-0 bg-[#FFFFFF5C]' : 'left-[150px] bg-[#FFFFFF81]'
					}`}
				>
					{!isHover ? (
						<ExternalIcon className="h-4 w-4 fill-primary" />
					) : (
						<RightIcon className="fill-primary" />
					)}
				</div>
				<span
					className={`text-[13px] font-medium uppercase text-primary ${
						!isHover ? 'ml-[30px]' : 'ml-2'
					}`}
				>
					{t('Go to landing page')}
				</span>
			</div>
		</a>
	)
}

export default GoToLandingPageButton
