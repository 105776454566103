import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Layout from 'layouts/Layout'
import ContainerWrapper from 'components/Wrapper/ContainerWrapper'
import ContentWrapper from 'components/Wrapper/ContentWrapper'
import Information from 'components/Information'
import InviteFriendsModal from 'components/Modal/InviteFriends'
import SignTransactionModal from 'components/Modal/SignTransaction'
import { amountFormatter, dollarFormatter } from 'utils/formatter'
import LoadingModal from 'components/Modal/Loading'
import { toast } from 'react-toastify'
import { perpetualStakingPools } from 'config/perpetualStakingPools'
import { ethers } from 'ethers'
import { PerpetualTokenRewardsAbi } from 'config/abis'
import { useEthers } from '@usedapp/core'
import { useProvider } from 'hooks/useProvider'
import useASSCountDown from 'hooks/useASSCountDown'
import {CommonContext} from "../../contexts/common";
import {RewardsContext} from "../../contexts/rewards";

const ClaimRewards = () => {
	const timerRef = useRef<NodeJS.Timer>()
	const [countDownStr, setCountDownStr] = useState<string>('00 : 00 : 00')
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false)
	const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false)
	const [signModalOpen, setSignModalOpen] = useState<boolean>(false)
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [mutate, setMutate] = useState<boolean>(false)
	const {
		tokenPrice,
		collectedRewards,
		marketValue,
		poolRewards,
		remainingClaims,
		referralHandlerBalance,
		depositBoxBalance,
		claimReferralReward,
		claimDepositBoxReward,
	} = useContext(RewardsContext)
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'
	const library = useProvider()
	const { node } = useContext(CommonContext)
	const countDown = useASSCountDown()

	const updateTime = useCallback(() => {
		if (!countDown) {
			return
		}

		const timestamp = Math.floor(new Date().getTime() / 1000)
		const delta = countDown - timestamp
		if (delta < 0) {
			clearInterval(timerRef.current)
			return
		}

		const newDurationString =
			Math.floor(delta / 3600)
				.toString()
				.padStart(2, '0') +
			':' +
			Math.floor((delta / 60) % 60)
				.toString()
				.padStart(2, '0') +
			':' +
			Math.floor(delta % 60)
				.toString()
				.padStart(2, '0')

		setCountDownStr(newDurationString)
	}, [countDown])

	useEffect(() => {
		if (countDown) {
			timerRef.current = setInterval(updateTime, 1000)
		}

		return () => clearInterval(timerRef.current)
	}, [countDown, updateTime])

	const handleClaimPoolRewards = async () => {
		if (!library || !account) {
			return
		}
		try {
			for (const stakingPool of perpetualStakingPools) {
				const stakingContract = new ethers.Contract(
					stakingPool.stakingAddress,
					PerpetualTokenRewardsAbi,
					library.getSigner()
				)
				setWaitingModalOpen(true)
				const collected = (await stakingContract.rewards(account)) as ethers.BigNumber
				setWaitingModalOpen(false)

				if (parseFloat(ethers.utils.formatEther(collected)) === 0) {
					continue
				}

				setSignModalOpen(true)
				const tx = await stakingContract.getReward()
				setSignModalOpen(false)

				setWaitingModalOpen(true)
				await tx.wait()
				setWaitingModalOpen(false)
			}
			toast.success('Successfully claimed')

			setMutate((prev) => !prev)
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const handleClaimReferralReward = async () => {
		try {
			setSignModalOpen(true)
			const tx = await claimReferralReward()
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			setMutate((prev) => !prev)
			toast.success('Successfully claimed')
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	const handleClaimDepositReward = async () => {
		try {
			setSignModalOpen(true)
			const tx = await claimDepositBoxReward()
			setSignModalOpen(false)

			setWaitingModalOpen(true)
			await tx.wait()
			setWaitingModalOpen(false)

			setMutate((prev) => !prev)
			toast.success('Successfully claimed')
		} catch (err: any) {
			setSignModalOpen(false)
			setWaitingModalOpen(false)
			console.log(err)

			toast.error(err.message)
		}
	}

	return (
		<Layout title="QUOTA Webapp - Claim Rewards">
			<InviteFriendsModal
				referralId={node?.referralHandlerAddress}
				isOpen={isInviteModalOpen}
				closeModal={() => setIsInviteModalOpen(false)}
			/>
			<SignTransactionModal isOpen={signModalOpen} />
			<LoadingModal isOpen={waitingModalOpen} />
			<ContainerWrapper title={t('Claim Rewards')}>
				<div className="grid gap-12 xl:gap-5">
					<div className="grid grid-cols-2 gap-20 xl:gap-5 lg:grid-cols-1">
						<ContentWrapper className="px-6 py-8 md:px-4 md:py-3">
							<div className="flex items-center justify-between xs:flex-col xs:items-start">
								<p className="text-base font-bold text-gray md:font-medium">
									{t('Total Rewards Collected')}:
								</p>
								<div className="flex items-center justify-between gap-3 md:gap-2">
									<p className="text-2xl font-bold text-primary md:text-base md:font-normal">
										{collectedRewards !== undefined
											? amountFormatter.format(collectedRewards)
											: '-'}
									</p>
									<img
										src="/images/quota-coin.png"
										alt=""
										className="h-[30px] w-[30px] md:h-5 md:w-5"
									/>
								</div>
							</div>
						</ContentWrapper>
						<ContentWrapper className="px-6 py-8 md:px-4 md:py-3">
							<div className="flex items-center justify-between xs:flex-col xs:items-start">
								<p className="text-base font-bold text-gray md:font-medium">
									{t('Current Market Value')}:
								</p>
								<p className="text-2xl font-bold text-primary md:text-base md:font-normal">
									{marketValue !== undefined ? `$ ${dollarFormatter.format(marketValue)}` : '-'}
								</p>
							</div>
						</ContentWrapper>
					</div>
					<div className="grid grid-cols-3 gap-24 xl:gap-5 lg:grid-cols-2 sm:grid-cols-1">
						<ContentWrapper className="py-12 px-4 md:py-6">
							<div className="flex flex-col items-center">
								<div className="relative">
									<h2 className="text-xl font-medium text-primary">{t('Staking Pool Rewards')}</h2>
									<div className="absolute -top-1 right-0 z-10 translate-x-full">
										<Information
											description={t('Collect all your staking pool rewards from one place.')}
											width={200}
										/>
									</div>
								</div>
								<div className="mt-16 md:mt-12">
									<div className="flex items-center gap-2.5">
										<p className="text-4xl text-primary md:text-3xl">
											{poolRewards !== undefined ? amountFormatter.format(poolRewards) : '-'}
										</p>
										<img src="/images/quota-coin.png" alt="" className="h-9 w-9 md:h-7 md:w-7" />
									</div>
								</div>
								<p className="mt-3 text-center text-xl text-primary">
									{poolRewards !== undefined && tokenPrice !== undefined
										? `$ ${dollarFormatter.format(poolRewards * tokenPrice)}`
										: '-'}
								</p>
								<button
									className="btn invisible mt-16 h-12 w-64"
									onClick={handleClaimPoolRewards}
									disabled={!account || !library || !poolRewards}
								>
									{t('Claim Rewards')}
								</button>
								<button className="btn mt-3 h-12 w-64" onClick={() => navigate('/staking-pools')}>
									{t('Stake / Unstake')}
								</button>
							</div>
						</ContentWrapper>
						<ContentWrapper className="py-12 px-4 md:py-6">
							<div className="flex flex-col items-center">
								<div className="relative">
									<h2 className="text-xl font-medium text-primary">{t('Referral Rewards')}</h2>
									<div className="absolute -top-1 right-0 z-10 translate-x-full">
										<Information
											description={t(
												"To participate in Quota's membership exclusive on-chain referral program, mint your Quota Membership NFT and start sharing your referral code"
											)}
											width={240}
										/>
									</div>
								</div>
								<div className="mt-16 md:mt-12">
									<div className="flex items-center gap-2.5">
										<p className="text-4xl text-primary md:text-3xl">
											{referralHandlerBalance !== undefined
												? amountFormatter.format(referralHandlerBalance)
												: '-'}
										</p>
										<img src="/images/quota-coin.png" alt="" className="h-9 w-9 md:h-7 md:w-7" />
									</div>
								</div>
								<p className="mt-3 text-center text-xl text-primary">
									{referralHandlerBalance !== undefined && tokenPrice !== undefined
										? `$ ${dollarFormatter.format(referralHandlerBalance * tokenPrice)}`
										: '-'}
								</p>
								<div className="relative mt-16 h-12 w-fit text-sm text-gray">
									{t('Unclaimed Self-Tax Rewards')}:{' '}
									<b className="text-primary">{remainingClaims ?? '-'}</b>
									<div className="absolute -top-3 right-0 z-10 translate-x-full">
										<Information
											description={t(
												'Users can reclaim a portion of the tax on the event of a positive AAS.'
											)}
											width={256}
										/>
									</div>
								</div>
								<button
									className="btn relative mt-3 h-12 w-64"
									disabled={!referralHandlerBalance && !remainingClaims}
									onClick={handleClaimReferralReward}
								>
									{t('Claim Rewards')}
									<div className="absolute -top-3 right-0 z-10 translate-x-full">
										<Information
											description={t(
												'*When you mine your Referral Rewards, 30% tax is automatically deducted from your rewards. This tax will be automatically re-distributed to replenish the Staking Reward Pool, Dev Pool, Reward Pool Escrow, and Tier Reward Distribution.'
											)}
											width={300}
										/>
									</div>
								</button>
							</div>
						</ContentWrapper>
						<ContentWrapper className="py-12 px-4 lg:col-span-2 md:py-6 sm:col-span-1">
							<div className="flex flex-col items-center">
								<div className="relative">
									<h2 className="text-xl font-medium text-primary">{t('Staking AAS Rewards')}</h2>
									<div className="absolute -top-1 right-0 translate-x-full">
										<Information
											description={t('Your Staking AAS Rewards are distributed once every 7 days.')}
											width={288}
										/>
									</div>
								</div>
								<div className="mt-16 md:mt-12">
									<div className="flex items-center gap-2.5">
										<p className="text-4xl text-primary md:text-3xl">
											{depositBoxBalance !== undefined
												? amountFormatter.format(depositBoxBalance)
												: '-'}
										</p>
										<img src="/images/quota-coin.png" alt="" className="h-9 w-9 md:h-7 md:w-7" />
									</div>
								</div>
								<p className="mt-3 text-center text-xl text-primary">
									{depositBoxBalance !== undefined && tokenPrice !== undefined
										? `$ ${dollarFormatter.format(depositBoxBalance * tokenPrice)}`
										: '-'}
								</p>
								<div className="invisible mt-16 h-12 text-xl tracking-[4px] text-primary">
									{countDownStr}
								</div>
								<button
									className="btn mt-3 h-12 w-64"
									disabled={!depositBoxBalance}
									onClick={handleClaimDepositReward}
								>
									{t('Claim Rewards')}
								</button>
							</div>
						</ContentWrapper>
					</div>
					<ContentWrapper className="px-[60px] py-5 md:px-6 sm:py-4 xs:px-2">
						<div className="flex items-center justify-between gap-3 sm:flex-col">
							<p className="text-xl font-medium tracking-[0.4px] text-primary md:text-base xs:text-[10px] xs:font-normal">
								{t('Invite your friends to participate to earn more rewards')}
							</p>
							<button
								className="btn h-14 w-[300px] md:h-10 md:w-[232px]"
								onClick={() => setIsInviteModalOpen(true)}
							>
								{t('Invite your friends')}
							</button>
						</div>
					</ContentWrapper>
				</div>
			</ContainerWrapper>
		</Layout>
	)
}

export default ClaimRewards
