import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEthers } from '@usedapp/core'

import Layout from 'layouts/Layout'
import ContainerWrapper from 'components/Wrapper/ContainerWrapper'
import ContentWrapper from 'components/Wrapper/ContentWrapper'
import useCurrentData from 'hooks/useCurrentData'
import { tiers } from 'config/tiers'
import { formatEther } from 'ethers/lib/utils'
import { amountFormatter, dollarFormatter } from 'utils/formatter' //eslint-disable-line
import useNFTContract from 'hooks/useNFTContract'
import useNodeAnalyze from 'hooks/useNodeAnalyze'

interface DataFormProps {
	label: string
	value: number | undefined
	is$?: boolean
	isCoin?: boolean
}

export const DataForm = (props: DataFormProps) => {
	const { label, value, is$ = false, isCoin = false } = props
	const { t } = useTranslation()

	return (
		<div className="flex flex-col gap-1">
			<p className="text-xs font-semibold text-gray xs:hidden">{t(label)}</p>
			<ContentWrapper className="p-5 xs:px-4 xs:py-3">
				<p className="mb-1 hidden text-base font-medium text-gray xs:block">{t(label)}</p>
				<div className="flex items-center justify-between text-[17px] font-bold text-primary xs:text-[15px] xs:font-semibold">
					{is$ ? '$' : ''}
					{value?.toLocaleString() ?? '-'}
					{isCoin ? <img src="/images/quota-coin.png" alt="" className="h-5 w-5" /> : ''}
				</div>
			</ContentWrapper>
		</div>
	)
}

const Home = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { active } = useEthers()
	const { nftTier } = useNFTContract()
	const node = useNodeAnalyze()

	const { tvl, totalSupply, circulatingSupply, tokenBalance, stakedBalance, marketValue } =
		useCurrentData()

	return (
		<Layout title="QUOTA Webapp">
			<ContainerWrapper title="Home">
				<div className="flex gap-6 xl:flex-col xl:items-center">
					<div className="w-full max-w-[577px] flex-shrink-0 xl:max-w-none">
						<ContentWrapper className="px-[60px] py-12 md:p-6">
							<div className="flex flex-col items-center">
								{nftTier ? (
									<img
										src={`/images/nfts/${tiers[tiers.length - nftTier - 1].toLowerCase()}.png`}
										alt=""
										className="h-full w-[240px] py-8 sm:w-[144px]"
									/>
								) : (
									<img
										src="/images/nft-logo.png"
										alt=""
										className="h-full w-[240px] py-8 sm:w-[144px]"
									/>
								)}
								{!nftTier && active ? (
									<button className="btn mt-6 uppercase md:mt-5" onClick={() => navigate('/nft')}>
										{t('Mint NFT')}
									</button>
								) : (
									<></>
								)}
								<div className="mt-16 flex gap-8 md:mt-6">
									<p className="text-1">{t('Referrals Below')}:</p>
									<p className="text-1">{node?.totalReferrals ?? '-'}</p>
								</div>
							</div>
							<table className="mt-10 w-full md:mt-5">
								<thead>
									<tr>
										{['Tiers', 'Numbers', 'Staked Amount', 'Value Staked'].map((item, index) => (
											<th
												className="table-header w-[27%] pb-4 first:w-[19%] first:text-left"
												key={index}
											>
												{t(item)}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{Array(5)
										.fill(0)
										.map((_, index) => (
											<tr key={index}>
												<td className="table-body pb-2 text-left">{t(tiers[5 - index])}:</td>
												<td className="table-body">{node?.tiersInfo?.at(index) ?? '-'}</td>
												<td className="table-body border-l border-r border-gray">
													{node && (node?.stakedBalance?.length ?? 0) > 0
														? amountFormatter.format(
																parseFloat(formatEther(node?.stakedBalance?.[index]))
														  )
														: '-'}
												</td>
												<td className="table-body">
													{node && (node?.stakedBalance?.length ?? 0) > 0
														? '$' +
														  dollarFormatter.format(
																parseFloat(formatEther(node?.valueStaked?.[index])) * 2
														  )
														: '-'}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</ContentWrapper>
					</div>
					<div className="flex w-full flex-col justify-between gap-24 xl:gap-5">
						{/* <div> */}
						<ContentWrapper className="flex h-full items-center justify-center py-8 px-2">
							<div className="flex items-center">
								<img src="/images/logo.png" alt="" className="h-36 w-36 sm:h-20 sm:w-20" />
								<div className="-ml-10 rounded-[10px] border border-[#FFFFFF26] bg-[#fff3] p-8 text-4xl font-bold  tracking-[6px] text-primary shadow-[inset_0px_3px_6px_#0000002F] backdrop-blur-xl backdrop-brightness-125 sm:-ml-7 sm:rounded sm:p-2 sm:text-xl">
									COMING SOON
								</div>
							</div>
						</ContentWrapper>
						{/* <HomeChart /> */}
						{/* </div> */}
						<div className="grid grid-cols-3 gap-x-12 gap-y-16 xl:mt-5 xl:gap-5 sm:grid-cols-2 xs:grid-cols-1">
							<DataForm label="Total Supply" value={totalSupply} is$={false} isCoin={false} />
							<DataForm
								label="Circulating Supply"
								value={circulatingSupply}
								is$={false}
								isCoin={false}
							/>
							<DataForm label="TVL" value={tvl} is$={true} isCoin={false} />
							<DataForm label="Wallet Balance" value={tokenBalance} is$={false} isCoin={true} />
							<DataForm label="Staked Balance" value={stakedBalance} is$={false} isCoin={true} />
							<DataForm label="Earnings" value={marketValue} is$={true} isCoin={false} />
						</div>
					</div>
				</div>
			</ContainerWrapper>
		</Layout>
	)
}

export default Home
