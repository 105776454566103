import { ethers } from 'ethers'
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers'
import memoizee from "memoizee";


export const contractFetcherFn =
	<T>(
		library: JsonRpcProvider | JsonRpcSigner,
		contractAbi: any,
		additionalArgs: any[] | undefined = undefined
	) => memoizee((args: any[]): Promise<T> => {
		// eslint-disable-next-line
		const [id, arg0, arg1, ...params] = args

		const method = ethers.utils.isAddress(arg0) ? arg1 : arg0

		const contractCall = getContractCall(
			library,
			contractAbi,
			arg0,
			arg1,
			method,
			params,
			additionalArgs
		)
		return new Promise(async (resolve, reject) => {
			contractCall
				.then((result: any) => {
					resolve(result)
				})
				.catch((e: any) => {
					// console.error('fetcher error', id, method, e)
				})
		})
	}, {promise: true})


export const contractFetcher = memoizee(contractFetcherFn)

function getContractCall(
	provider: JsonRpcProvider | JsonRpcSigner,
	contractAbi: any,
	arg0: string,
	arg1: string | number | boolean,
	method: string,
	params: (string | number | boolean)[],
	additionalArgs: (string | number | boolean)[] | undefined
) {
	if (ethers.utils.isAddress(arg0)) {
		const address = arg0
		const contract = new ethers.Contract(address, contractAbi, provider)

		if (additionalArgs) {
			return contract[method](...params.concat(additionalArgs))
		}
		return contract[method](...params)
	}

	if (!provider) {
		return
	}

	return (provider as any)[method](arg1, ...params)
}
