import { formatEther } from 'ethers/lib/utils'
import { useState, useEffect } from 'react'
import { apiServer } from 'utils/apiServer'

const useDailyVolumes = (mutate: boolean = false) => {
	const [dailyVolumes, setDailyVolumes] = useState<ChartDataType[]>([])

	useEffect(() => {
		;(async () => {
			try {
				const response = await apiServer.get('/chart/daily_volume')
				setDailyVolumes(
					(response.data?.data ?? []).map((item: any) => {
						return {
							value: parseFloat(formatEther(item?.value ?? '0')),
							timestamp: item?.timestamp,
						} as ChartDataType
					})
				)
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate])

	return dailyVolumes
}

export default useDailyVolumes
