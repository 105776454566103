import { useState, useEffect } from 'react'
import { apiServer } from 'utils/apiServer'
import { formatEther } from 'ethers/lib/utils'

const useDailyASS = (mutate: boolean = false) => {
	const [dailyASS, setDailyASS] = useState<ChartDataType[]>([])

	useEffect(() => {
		;(async () => {
			try {
				const response = await apiServer.get('/chart/aas')
				setDailyASS(
					(response.data?.data ?? []).map((item: any) => {
						return {
							value: parseFloat(formatEther(item?.value ?? '0')),
							timestamp: item?.timestamp,
						} as ChartDataType
					})
				)
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate])

	return dailyASS
}

export default useDailyASS
