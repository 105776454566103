import ContentWrapper from 'components/Wrapper/ContentWrapper'

const tokens = [
	{
		name: 'WETH',
		unit: 'eth',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'MATIC',
		unit: 'matic',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'USDT',
		unit: 'USDT',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'GEN',
		unit: 'gen',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
	{
		name: 'GEN V2',
		unit: 'gen',
		APY: 0.23,
		currentRewards: 0,
		stakedLPTokens: 0,
		LPTokenBalance: 0,
	},
]

interface TokenComponentProps {
	token: {
		name: string
		unit: string
		APY: number
		currentRewards: number
		stakedLPTokens: number
		LPTokenBalance: number
	}
}

export const TokenComponent = (props: TokenComponentProps) => {
	const { name, unit, APY, currentRewards, stakedLPTokens, LPTokenBalance } = props.token

	return (
		<ContentWrapper className="px-6 py-10 lg:py-6">
			<div className="flex flex-col items-center text-gray-dark">
				<div className="flex flex-col items-center gap-6 lg:gap-3">
					<div className="flex items-end opacity-50">
						<img
							src={`/images/coin/${unit}.png`}
							alt=""
							className="z-10 mb-8 -mr-8 h-32 w-32 rounded-full lg:mb-2 lg:-mr-4 lg:h-[72px] lg:w-[72px]"
						/>
						<img src="/images/quota-coin.png" alt="" className="h-32 w-32 lg:h-16 lg:w-16" />
					</div>
					<div className="text-center">
						<p className="text-3xl font-bold lg:text-xl">{name} - 4.0</p>
						<p className="text-sm font-semibold lg:text-xs">{APY}% APY</p>
					</div>
				</div>
				<div className="mt-16 flex w-full flex-col gap-5 lg:mt-12 lg:gap-3 md:mt-5">
					<p className="text-lg font-semibold lg:text-sm">Current Rewards</p>
					<div className="flex items-center justify-between">
						<p className="text-2xl font-bold lg:text-base">{currentRewards}</p>
						<button className="btn lg:h-9 lg:w-[130px]">Collect</button>
					</div>
					<div className="border-t border-gray-3" />
					<p className="text-lg font-semibold lg:text-sm">Staked LP Tokens</p>
					<div className="flex items-center justify-between">
						<p className="text-2xl font-bold  lg:text-base">{stakedLPTokens}</p>
						<button className="btn lg:h-9 lg:w-[130px]">Unstake</button>
					</div>
					<div className="border-t border-gray-3" />
					<p className="text-lg font-semibold lg:text-sm">LP Token Balance</p>
					<div className="flex items-center justify-between">
						<p className="text-2xl font-bold lg:text-base">{LPTokenBalance}</p>
						<button className="btn lg:h-9 lg:w-[130px]">Approve</button>
					</div>
				</div>
			</div>
		</ContentWrapper>
	)
}

const PerpetualStaking = () => {
	return (
		<div className="grid grid-cols-2 gap-10 lg:gap-5 sm:grid-cols-1">
			{tokens.map((token, index) => (
				<TokenComponent token={token} key={index} />
			))}
		</div>
	)
}

export default PerpetualStaking
