import { useEffect } from 'react'
import { useEthers } from '@usedapp/core'
import { apiServer } from 'utils/apiServer'
import useAppStatus from './useAppStatus'

const useNodeAnalyze = (mutate: boolean = false) => {
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'
	const { nodeAnalyze, setNodeAnalyze } = useAppStatus()

	useEffect(() => {
		if (!account || nodeAnalyze !== undefined) {
			return
		}
		;(async () => {
			try {
				const response = await apiServer.get(`/tree/home?ownerAddress=${account}`)
				setNodeAnalyze(response.data?.node as NFTNodeAnalyze)
			} catch (err) {
				console.log(err)
			}
		})()
	}, [mutate, account, nodeAnalyze, setNodeAnalyze])

	return nodeAnalyze
}

export default useNodeAnalyze
