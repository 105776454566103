import { useTranslation } from 'react-i18next'

// import { tableData } from 'config'
import ProChart from 'components/Chart/Pro'
import { useState } from 'react'
import useDailyASS from 'hooks/useDailyASS'
import useTokenPrices from 'hooks/useTokenPrices'
import useMarketCaps from 'hooks/useMarketCaps'
import useTotalSupply from 'hooks/useTotalSupplys'
import useLiquidityDatas from 'hooks/useLiquidityData'
import useDailyVolumes from 'hooks/useDailyVolumes'

const Charts = () => {
	const { t } = useTranslation()
	// const [mutate, setMutate] = useState<boolean>(false)
	const mutate = false
	const [periods, setPeriods] = useState<PeriodList>({
		dailyAAS: 'week',
		tokenPrice: 'week',
		marketCap: 'week',
		totalSupply: 'week',
		liquidity: 'week',
		dailyVolume: 'week',
	})

	const dailyASSData = useDailyASS(mutate)
	const tokenPriceData = useTokenPrices(periods.tokenPrice, mutate)
	const marketCapData = useMarketCaps(periods.marketCap, mutate)
	const totalSupplyData = useTotalSupply(periods.totalSupply, mutate)
	const liquidityData = useLiquidityDatas(mutate)
	const dailyVolumeData = useDailyVolumes(mutate)

	const handlePeriodChange = (name: string, _period: ChartType) => {
		setPeriods({ ...periods, [name]: _period })
	}

	return (
		<div className="grid gap-10 xl:gap-5">
			<ProChart
				title={t('Daily AAS')}
				type="line"
				isInfo={true}
				data={dailyASSData}
				onPeriodChange={(_period) => handlePeriodChange('dailyAAS', _period)}
			/>
			<div className="grid grid-cols-2 gap-10 xl:grid-cols-1 xl:gap-5">
				<ProChart
					title={t('Token Price')}
					type="polygon"
					data={tokenPriceData.map((item) => {
						return {
							timestamp: item.timestamp,
							value: item.open,
						}
					})}
					onPeriodChange={(_period) => handlePeriodChange('tokenPrice', _period)}
				/>
				<ProChart
					title={t('Market Cap')}
					type="area"
					data={marketCapData}
					onPeriodChange={(_period) => handlePeriodChange('marketCap', _period)}
				/>
			</div>
			<ProChart
				title={t('Total Supply')}
				type="line"
				data={totalSupplyData}
				onPeriodChange={(_period) => handlePeriodChange('totalSupply', _period)}
			/>
			<div className="grid grid-cols-2 gap-10 xl:grid-cols-1 xl:gap-5">
				<ProChart
					title={t('Liquidity')}
					type="area"
					data={liquidityData}
					onPeriodChange={(_period) => handlePeriodChange('liquidity', _period)}
				/>
				<ProChart
					title={t('Daily Volume')}
					type="bar"
					data={dailyVolumeData}
					onPeriodChange={(_period) => handlePeriodChange('dailyVolume', _period)}
				/>
			</div>
		</div>
	)
}

export default Charts
