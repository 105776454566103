import {createContext, FC, PropsWithChildren, useCallback, useEffect, useState} from "react";
import {apiServer} from "../utils/apiServer";
import {useEthers} from "@usedapp/core";
import useAppStatus from "../hooks/useAppStatus";
import {formatEther} from "ethers/lib/utils";

export interface ICommonContext {
    lastASS?: ASSType;
    node?: NFTNode;
    allNodes: NFTNode[];
    tokenPrice?: number;
}
export const CommonContext = createContext<ICommonContext>(null as any);

export const CommonContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [lastASS, setLastASS] = useState<ASSType>();
    const { account } = useEthers()
    const { node, setNode, tokenPrice, setTokenPrice } = useAppStatus()
    const [allNodes, setAllNodes] = useState<NFTNode[]>([])

    const fetchLastASS = useCallback(async () => {
        try {
            const response = await apiServer.get('/aas')
            setLastASS(response.data?.data as ASSType)
        } catch (err) {
            console.log(err)
        }
    }, []);

    const fetchNode = useCallback(async (account: string) => {
        try {
            const response = await apiServer.get(`/tree/node?ownerAddress=${account}`)
            setNode(response.data?.node as NFTNode)
        } catch (err) {
            console.log(err)
        }
    }, []);

    const fetchAllNodes = useCallback(async (account: string) => {
        if (!account) return
        try {
            const response = await apiServer.get(`/tree/get-all-referrals?owner=${account}`)
            setAllNodes((response.data?.referrers ?? []).map((item: any) => item?.node as NFTNode))
        } catch (err) {
            console.log(err)
        }
    }, [])

    const fetchTokenPrice = useCallback(async () => {
        try {
            const response = await apiServer.get('/token/token_price')
            setTokenPrice(parseFloat(formatEther(response.data?.price ?? '0')))
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        if (!account) return;
        fetchNode(account);
        fetchAllNodes(account);
    }, [account])

    useEffect(() => {
        fetchLastASS()
        fetchTokenPrice()
    }, [])

    return <CommonContext.Provider value={{ lastASS, node, allNodes, tokenPrice }}>{children}</CommonContext.Provider>
}