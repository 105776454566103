const koJSON = {
	ENG: '한국어',
	Pro: '쿠오타 프로',
	'Add Liquidity': '유동성 공급',
	'Staking Pools': '스테이킹 풀',
	'Claim Rewards': '리워드 청구',
	Leaderboard: '리더 보드',
	'Launch Pad': '런치패드',
	NFT: 'NFT',
	Bonds: '채권',
	'Coming soon': 'Coming soon',
	'Go to landing page': '랜딩페이지 돌아가기',
	'Token Address': 'Token Address',
	'Make sure to select “<b>Market(V2)</b>” for swap':
		'4.0 스왑을 위해서 “<b>Market(V2)</b>“을 선택하시기 바랍니다.',
	Disclaimer: '안내사항',
	'Quota is not an investment product.': 'Quota는 투자 상품이 아닙니다.',
	'It is not intended in its design or distribution to be utilized as a form of investment, speculation, or a financial product. No communications from Quota Network to users constitute financial advice, a solicitation for investment, or a guarantee of a financial return. Please conduct your own due diligence and research before participating in the Quota Network.':
		'투자, 투기 또는 금융 상품의 형태로 활용되도록 설계 또는 배포되지 않았으며, Quota Network에서 사용자에게 어떠한 재정적 조언, 투자 권유 또는 금전적 수익 보장을 구성하지 않습니다. 본프로젝트에 참여하기 전에 각자의 조사를 하시기 바랍니다.',
	Accept: '확인',
	"Don't show for today": '오늘 하루 보지 않기',
	'Connect Wallet': '월렛커넥트',
	'My Stats': 'My Stats',
	'Token Price': '토큰 가격',
	'AAS Countdown': 'AAS Countdown',
	'Yesterday’s AAS': '어제 자 AAS',
	'Token Balance': 'Token Balance',
	'Staked Balance': '스테이킹 잔고',
	'Unclaimed Balance': 'Unclaimed Balance',
	'Mint NFT': 'Mint NFT',
	'Referrals Below': '추천인 보유',
	Tiers: '티어',
	Numbers: '인원수',
	'Staked Amount': '스테이킹 물량',
	'Value Staked': '스테이킹 가치',
	Black: '블랙',
	Diamond: '다이아몬드',
	Gold: '골드',
	Silver: '실버',
	Bronze: '브론즈',
	'Market Cap': '시가 총액',
	'Total Supply': '총 공급량',
	'Circulating Supply': '유통량',
	TVL: '예치된 총 자산',
	'Wallet Balance': '지갑 잔고',
	Earnings: '수익',
	Charts: '차트',
	Lists: '리스트',
	'Daily AAS': '일일 AAS',
	Liquidity: '유동성',
	'Daily Volume': '일일 거래량',
	"AAS: 'Automatically Adjusted Supply' is Quota's algorithmic equilibrium seeking token supply moderator. The price action of 4.0 is systematically logged in 1 hour intervals over a 24 hour period to determine the degree of deviation from S&P 500. This deviation data is then processed in out Automatically Adjusted Supply ('AAS') algorithm, to adjust the token supply accordingly.":
		'쿠오타의 AAS 시스템은 프로토콜 내 독점적인 알고리즘 기반 토큰 공급 중재자입니다. 쿠오타 프로토콜은 AAS 시스템을 통해 자동적으로 토큰 공급 증대 및 감축을 통해 균형을 맞추어 가격을 유지합니다. S&P 500 지수를 기반으로 하는 QEX의 PA(가격 액션)은 조정에 관련된 기하학적 요소들을 결정합니다. 쿠오타 프로토콜은 S&P 500 지수와의 편차 정도를 결정하기 위해 1시간 간격으로 QEX 토큰의 PA(가격 액션)을 매우 체계적으로 기록합니다. 이후 기록된 해당 편차 데이터들은 AAS(Automatically Adjusted Supply) 알고리즘에서 처리되어 토큰 공급의 변화량을 결정하게 됩니다.',
	Tip: '',
	'When you add liquidity, you will receive LP tokens corresponding to the amount of your share of the pool. These tokens can be used to stake from the staking pools page. Staking these LP tokens will accrue rewards over time.':
		'유동성을 추가하면 풀 지분에 해당하는 양의 LP 토큰을 받게 됩니다. 이 토큰은 스테이킹 풀 페이지에서 스테이킹하는 데 사용할 수 있습니다. 이 LP 토큰을 스테이킹하면 시간에 따라 스테이킹 보상이 누적됩니다.',
	MAX: 'MAX',
	Balance: '잔액',
	'Prices and pool share': '가격 및 페어풀 지분 ',
	'Share of Pool': '페어풀 지분',
	Supply: '공급 추가',
	Approve: '공급 추가',
	'Invalid pair': '페어 선택 불가',
	'Please sign transaction in your wallet.': '지갑에서 트랜잭션을 승인해주세요!',
	'Loading, please wait a little…': 'Loading, please wait a little…',
	'Staking pools': '스테이킹 풀',
	'Perpetual Staking V2': '영구 스테이킹 V2',
	APY: 'APY',
	'Current Rewards': '스테이킹 리워드',
	Collect: '청구',
	Unstake: '스테이킹 해지',
	Stake: '스테이킹',
	Amount: '물량',
	'LP Balance Available': '사용 가능 LP 토큰',
	'Lock Duration': '락업 기간',
	Days: '일',
	'Remaining Lock Duration': '남은 락업 기간',
	Time: '시간',
	'You still have “Remaining Lock Duration”': '락업 기간이 남아있습니다',
	stake: '스테이킹',
	'Invite your friends to earn more rewards!': '친구를 초대해서 더 많은 리워드를 받아보아요!',
	'Earn up to 50% more rewards for leveling up your NFTs by inviting more friends!':
		'더 많은 친구를 초대하여 여러분의 NFT를 레벨업하세요! 최대 50% 더 많은 리워드를 보상받을 수 있습니다',
	'Total Rewards Collected': '총 보상',
	'Current Market Value': '현재 시장가치',
	'Collect all your staking pool rewards from one place.':
		'한 곳에서 모든 스테이킹 리워드를 청구하세요!',
	'Staking Pool Rewards': '스테이킹 풀 보상',
	'Stake / Unstake': '스테이킹/스테이킹 해지',
	"To participate in Quota's membership exclusive on-chain referral program, mint your Quota Membership NFT and start sharing your referral code":
		'Quota의 멤버십 전용 온체인 추천 프로그램에 참여하려면 Quota Membership NFT를 생성하고 추천 코드 공유를 시작하세요',
	'Users can reclaim a portion of the tax on the event of a positive AAS.':
		'Users can reclaim a portion of the tax on the event of a positive AAS.',
	'*When you mine your Referral Rewards, 30% tax is automatically deducted from your rewards. This tax will be automatically re-distributed to replenish the Staking Reward Pool, Dev Pool, Reward Pool Escrow, and Tier Reward Distribution.':
		'*추천 보상을 채굴하면 보상에서 30%의 세금이 자동으로 공제됩니다. 이 세금은 Staking Reward Pool, Dev Pool, Reward Pool Escrow 및 Tier Reward Distribution을 보충하기 위해 자동으로 재분배됩니다.',
	'Copy ID': '복사하기',
	'Copy Link': '복사하기',
	'Referral Link': '추천링크',
	Copied: '복사완료',
	'Your Staking AAS Rewards are distributed once every 7 days.':
		'스테이킹 AAS 보상은 7일마다 한 번씩 지급됩니다.',
	'Invite your friends to participate to earn more rewards':
		'새로운 친구와 함께 더 많은 리워드를 얻어보아요',
	'Invite your friends': '친구 초대하기',
	Membership: '멤버십',
	Gallery: '갤러리',
	Username: '사용자명',
	'REFERRER’S ID': '추천인 ID',
	'*This NFT represents your Membership and participation on this platform. All your rewards, referrals, revenue and other related information is linked to this NFT. By minting this NFT, you are agreeing to this terms and conditions of this platform. Please note, transfering this NFT to anyone would also transfer all your referrals and future revenue to them.':
		'이 NFT는 여러분의 멤버십과 플랫폼 참여도를 나타냅니다. 여러분의 리워드, 추천인 수, 수익 등 모든 관련 정보는 이 NFT에 연결되게 됩니다. NFT를 발급받게 될 시 쿠오타 플랫폼의 약관에 동의한 것으로 간주됩니다. NFT 관리는 특별히 조심하여 주시길 부탁드리며, NFT를 다른 사람에게 전송하는 것은 당신의 추천인과 보상 모두를 전달하는 것과 마찬가지임을 명심해주시길 바랍니다.',
	'My NFT': '사용자님의 NFT',
	'Referrrals Under User’s NFT': '나의 NFT 추천인 보유수',
	'Total Reward Collected': '총 리워드양',
	'Most Recent Reward Collected': '최근 리워드양',
	'Level Up my NFT': '레벨업 하기',
	'LEVEL UP': 'LEVEL UP',
	'MAX LEVEL REACHED': 'MAX LEVEL REACHED',
	'My Referral ID': '사용자님의 추천인 ID',
	'Your NFT (Tier’s) Top 3 Leaderboard': '내 등급 TOP3 알아보기',
	'New Referrals': '새로운 추천인 수(이번주)',
	Top: 'Top',
	'Invite Friends': '친구 초대하기',
	'Create a referral link': '추천 링크 생성하기',
	'Connect your wallet & Mint your NFT!': '월렛을 연결하고 NFT를 발행하세요!',
	All: '전체',
	'Information containing detailed level up conditions can be found on the landing page.':
		'자세한 등급 상향 조건 정보는 랜딩페이지에서 확인하실 수 있습니다.',
	'This level up tool is to simplify the process of meeting the staking requirements of the level up conditions. Please be aware that even if you click the “Level up NFT” button and meet the staking requirements for the level, your NFT will not level up until you meet the referral requirement for the next tier.':
		'이 NFT 등급 상향 기능은 다음 NFT 등급에서 요구되는 스테이킹 사항을 충족시키는 프로세스를 단순화하기 위한 기능 입니다.“NFT 등급 상향” 버튼을 클릭하고 다음 등급의 스테이킹 요구 사항을 충족하시더라도 다음 NFT 등급의 추천 요구 사항이 충족될 때까지 NFT등급이 상향되지 않는다는 점에 유의하십시오.',
	'I understand': '읽고 이해 하였습니다',
	Next: '다음',
	'YOUR CURRENT LEVEL': '현재 NFT 등급',
	'Choose Staking pool': '스테이킹 풀을 고르십시오',
	'MINIMUM REQUIRED TOKENS STAKED': '최소 토큰 스테이킹 조건',
	'MINIMUM STAKE REQUIRED FOR LEVEL UP': '다음 NFT 등급까지 최소 토큰 스테이킹 조건',
	'MINIMUM REQUIRED REFERRALS': '다음 NFT 등급까지 최소 추천 조건',
	'CHECK CURRENT REFERRALS': '나의 추천 현황 확인하기',
	'REQUIRED LOCK DURATION': '스테이킹 락 기간',
	DAYS: 'DAYS',
	'Level Up NFT': 'NFT 등급 상향',
	Congratulations: '축하합니다 ',
	'You have fulfilled the Staking requirement to level up to next tier':
		'다음 NFT 등급을 위한 스테이킹 요건을 충족했습니다!',
	'Please be reminded, if you have not met the Referral requirements yet, your NFT will not level up until the referral requirement has also been met.':
		'주의하십시오. 추천 요건이 충족되지 않은 경우 추천 요건이 충족될 때까지 NFT 등급이 올라가지 않음을 유의하시기 바랍니다.',
	'If you have met all of the requirements, please be mindful that the level up update might take around 10 minutes. Please come back later and refresh the page.':
		'다음 NFT등급의 모든 조건을 충족한 경우 레벨 업 업데이트에 약간의 시간이 소요될 수 있습니다. 나중에 다시 방문하여 페이지를 새로고침 하시기 바랍니다.',
	Close: 'Close',
	'7D': '7일',
	'30D': '30일',
	Timestamp: '시간 표기',
	Price: '가격',
	'Current TWAP': '시간가중 평균가격(TWAP)',
	'Time Weighted Average Price': '지속적으로 변하는 가격을 일정 주기에 따라 수집하여 평균을 계산하는 것을 의미합니다.',
	'Total Value Deposited / Total Value Locked': '탈중앙화 디파이 프로젝트에서 스마트 컨트랙트에 예치된 총 자산 가치를 의미합니다.',
	'AAS Hourly Log': 'AAS 시간별 로그',
	'Select a token': '페어 토큰 선택',
	'Sign Transaction': '트랜잭션 승인',
	'Unclaimed Self-Tax Rewards': '요청 가능한 수당'
}

export default koJSON
