import MyStats from 'components/MyStats'
import useWindowSize from 'hooks/useWindowSize'

interface Props {
	children?: React.ReactNode
	title: string
}

const ContainerWrapper = (props: Props) => {
	const { children, title } = props
	const { width } = useWindowSize()

	return (
		<div className="container">
			<div className="flex items-center justify-between">
				<h1 className="title">{title}</h1>
				<div>{(width ?? 10000) < 1024 ? <MyStats variant="small" /> : <></>}</div>
			</div>
			<div className="my-[60px] lg:my-10 md:my-6">{children}</div>
		</div>
	)
}

export default ContainerWrapper
