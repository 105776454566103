import { contracts } from './contracts'

export const perpetualStakingPools = [
	{
		name: 'WBNB',
		unit: 'bnb',
		pairAddress: contracts.BNBPair,
		stakingAddress: contracts.BNBStakingPool,
		multi: 1,
	},
	{
		name: 'USDT',
		unit: 'usdt',
		pairAddress: contracts.USDTPair,
		stakingAddress: contracts.USDTStakingPool,
		multi: 1,
	},
	{
		name: 'FIL',
		unit: 'fil',
		pairAddress: contracts.FILPair,
		stakingAddress: contracts.FILStakingPool,
		multi: 1,
	},
	{
		name: 'SRVN V2',
		unit: 'srvn',
		pairAddress: contracts.SRVNPair,
		stakingAddress: contracts.SRVNStakingPool,
		multi: 1,
	},
	{
		name: 'SRVN V1',
		unit: 'srvn',
		pairAddress: contracts.SRVNPair,
		stakingAddress: contracts.SRVNLegacyStakingPool,
		isLegacy: true,
		multi: 1,
	},
	{
		name: 'SRVN V1',
		unit: 'srvn',
		pairAddress: contracts.SRVNPair,
		stakingAddress: contracts.SRVNLegacyV2StakingPool,
		isLegacy: true,
		isLegacyV2: true,
		multi: 2,
	},
]
