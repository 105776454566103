import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Layout from 'layouts/Layout'
import ContainerWrapper from 'components/Wrapper/ContainerWrapper'
import PerpetualStakingV2 from './PerpetualStakingV2'
import InitialDistribution from './InitialDistribution'
import PerpetualStaking from './PerpetualStaking'
import { ReactComponent as BackIcon } from '@material-icons/svg/svg/west/outline.svg'

const StakingPools = () => {
	const [status, setStatus] = useState<number>(0)
	const { t } = useTranslation()

	return (
		<Layout title="QUOTA Webapp - Staking Pools">
			<ContainerWrapper title={t('Staking pools')}>
				<div
					className={`relative flex items-center gap-12 md:gap-6 ${
						status === 0 ? 'justify-center' : 'justify-between'
					} -mt-12 lg:-mt-8 md:-mt-0`}
				>
					{status !== 0 && (
						<div
							className="flex cursor-pointer items-center text-lg text-primary md:text-sm"
							onClick={() => setStatus(0)}
						>
							<BackIcon className="w-7 fill-orange md:w-5" />
							Back
						</div>
					)}
					{status === 0 && (
						<button className="h-[60px] w-[268px] rounded-full border border-orange text-xl text-orange md:h-fit md:w-fit md:border-none md:text-sm md:font-bold md:text-primary">
							{t('Perpetual Staking V2')}
						</button>
					)}
					{/* <div
						className={`absolute right-0 flex gap-12 text-center text-xl text-gray underline xl:relative md:gap-6 md:text-sm hidden`}
					>
						{status < 2 && (
							<p className="cursor-pointer" onClick={() => setStatus(1)}>
								Initial Distribution
							</p>
						)}
						{status % 2 === 0 && (
							<p className="cursor-pointer" onClick={() => setStatus(2)}>
								Perpetual Staking
							</p>
						)}
					</div> */}
				</div>
				<div className="mt-16 md:mt-5">
					{status === 0 ? (
						<PerpetualStakingV2 />
					) : status === 1 ? (
						<InitialDistribution />
					) : (
						<PerpetualStaking />
					)}
				</div>
			</ContainerWrapper>
		</Layout>
	)
}

export default StakingPools
