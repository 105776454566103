import PancakePair from './PancakePair.json'
import LiquidityExtension from './LiquidityExtension.json'
import PerpetualTokenRewards from './PerpetualTokenRewards.json'
import ERC20 from './ERC20.json'
import NFTFactory from './NFTFactory.json'
import NFT from './NFT.json'
import ReferralHandler from './ReferralHandler.json'
import DepositBox from './DepositBox.json'
import TierManager from './TierManager.json'
import SelfTaxCalculator from './SelfTaxCalculator.json'

export const PancakePairAbi = PancakePair
export const PerpetualTokenRewardsAbi = PerpetualTokenRewards
export const ERC20Abi = ERC20
export const LiquidityExtensionAbi = LiquidityExtension
export const NFTFactoryAbi = NFTFactory
export const NFTAbi = NFT
export const ReferralHandlerAbi = ReferralHandler
export const DepositBoxAbi = DepositBox
export const TierManagerAbi = TierManager
export const SelfTaxCalculatorAbi = SelfTaxCalculator
