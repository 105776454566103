import { ethers } from 'ethers'
import { useEthers } from '@usedapp/core'
import { JsonRpcProvider } from '@ethersproject/providers'
import { useMemo } from 'react'
import { rpcUrl } from 'utils/constants'

export const useProvider = () => {
	const { library } = useEthers()

	const provider = useMemo(
		() => (!library ? new ethers.providers.JsonRpcProvider(rpcUrl) : (library as JsonRpcProvider)),
		[library]
	)
	return provider
}
