import { useState, useEffect } from 'react'
import {
	XAxis,
	YAxis,
	AreaChart,
	Area,
	Tooltip,
	ResponsiveContainer,
	Line,
	LineChart,
	BarChart,
	Bar,
} from 'recharts'
import { timestampToString } from 'utils'

interface Props {
	data?: any[]
	type: 'area' | 'bar' | 'line' | 'polygon'
}

const CustomTooltip = (props: any) => {
	const { active, payload, label } = props
	if (active && payload && payload.length) {
		return (
			<div className="w-40 rounded-lg border-none bg-primary p-2 sm:w-24">
				<div className="sm:text-xs">{timestampToString(label)}</div>
				<div className="sm:text-xs">{payload[0].value}</div>
			</div>
		)
	}
	return null
}

const ChartForm = (props: Props) => {
	const { data, type } = props
	const [width, setWidth] = useState<number>(0)

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth)
		}

		window.addEventListener('resize', handleResize)

		handleResize()

		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return type === 'area' ? (
		<ResponsiveContainer width="100%" height="100%">
			<AreaChart data={data} margin={{ top: 30, right: width > 768 ? 30 : 10, left: 0, bottom: 0 }}>
				<defs>
					<linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
						<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
					</linearGradient>
				</defs>
				<XAxis
					dataKey="timestamp"
					tickFormatter={(v) => timestampToString(v)}
					tick={{ fill: '#EEF6FF' }}
				/>
				<YAxis
					tickCount={5}
					tick={{ fill: '#EEF6FF' }}
					width={width > 768 ? 60 : 30}
					domain={[(dataMin: any) => 0, (dataMax: any) => (dataMax * 1.5).toFixed()]}
				/>
				<Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />
				<Area
					type="monotone"
					dataKey="value"
					stroke="#8884d8"
					fillOpacity={1}
					fill="url(#colorPrice)"
				/>
			</AreaChart>
		</ResponsiveContainer>
	) : type === 'line' ? (
		<ResponsiveContainer width="100%" height="100%">
			<LineChart data={data} margin={{ top: 30, right: width > 768 ? 30 : 10, left: 0, bottom: 0 }}>
				<defs>
					<linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
						<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
					</linearGradient>
				</defs>
				<XAxis
					dataKey="timestamp"
					tickFormatter={(v) => timestampToString(v)}
					tick={{ fill: '#EEF6FF' }}
				/>
				<YAxis
					tickCount={5}
					tick={{ fill: '#EEF6FF' }}
					width={width > 768 ? 60 : 30}
					domain={[(dataMin: any) => 0, (dataMax: any) => (dataMax * 1.5).toFixed()]}
				/>
				<Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />
				<Line
					type="monotone"
					dataKey="value"
					stroke="#AD5E26"
					fillOpacity={1}
					fill="url(#colorPrice)"
				/>
			</LineChart>
		</ResponsiveContainer>
	) : type === 'bar' ? (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart data={data} margin={{ top: 30, right: width > 768 ? 30 : 10, left: 0, bottom: 0 }}>
				<defs>
					<linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
						<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
					</linearGradient>
				</defs>
				<XAxis
					dataKey="timestamp"
					tickFormatter={(v) => timestampToString(v)}
					tick={{ fill: '#EEF6FF' }}
				/>
				<YAxis
					tickCount={5}
					tick={{ fill: '#EEF6FF' }}
					width={width > 768 ? 60 : 30}
					domain={[(dataMin: any) => 0, (dataMax: any) => (dataMax * 1.5).toFixed()]}
				/>
				<Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />
				<Bar
					type="monotone"
					dataKey="value"
					stroke="#AD5E26"
					fillOpacity={1}
					fill="url(#colorPrice)"
				/>
			</BarChart>
		</ResponsiveContainer>
	) : (
		<ResponsiveContainer width="100%" height="100%">
			<LineChart data={data} margin={{ top: 30, right: width > 768 ? 30 : 10, left: 0, bottom: 0 }}>
				<defs>
					<linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
						<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
					</linearGradient>
				</defs>
				<XAxis
					dataKey="timestamp"
					tickFormatter={(v) => timestampToString(v)}
					tick={{ fill: '#EEF6FF' }}
				/>
				<YAxis
					tickCount={5}
					tick={{ fill: '#EEF6FF' }}
					width={width > 768 ? 60 : 30}
					domain={[(dataMin: any) => 0, (dataMax: any) => (dataMax * 1.5).toFixed()]}
				/>
				<Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />
				<Line
					type="step"
					dataKey="value"
					stroke="#AD5E26"
					fillOpacity={1}
					fill="url(#colorPrice)"
				/>
			</LineChart>
		</ResponsiveContainer>
	)
}

export default ChartForm
