import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Layout from 'layouts/Layout'
import ContainerWrapper from 'components/Wrapper/ContainerWrapper'
import { Charts, Lists } from './Sections'
import Button from 'components/Button'

const Pro = () => {
	const [activeButton, setActiveButton] = useState<string>('Charts')
	const { t } = useTranslation()

	return (
		<Layout title="QUOTA Webapp - Pro">
			<ContainerWrapper title={t('Pro')}>
				<div className="flex flex-col gap-9 md:xl:gap-5">
					<div className="mx-auto flex gap-12 md:gap-9">
						<Button
							variant="outlined"
							isActive={activeButton === 'Charts'}
							onClick={() => setActiveButton('Charts')}
						>
							{t('Charts')}
						</Button>
						<Button variant="outlined" isActive={activeButton === 'Lists'}>
							{t('Lists')}
						</Button>
					</div>
					<div>{activeButton === 'Charts' ? <Charts /> : <Lists />}</div>
				</div>
			</ContainerWrapper>
		</Layout>
	)
}

export default Pro
