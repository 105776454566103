interface Props {
	variant: 'contained' | 'filled' | 'outlined'
	size?: 'large' | 'medium' | 'small'
	isActive?: boolean
	children?: React.ReactNode
	onClick?: () => void
}

const Button = (props: Props) => {
	const { variant = 'outlined', size = 'medium', isActive = false, children, onClick } = props

	return variant === 'outlined' ? (
		<button
			className={`relative rounded-full border text-xl transition-none ${
				isActive ? 'border-orange text-orange' : 'border-primary text-primary'
			} ${
				size === 'medium'
					? 'h-[58px] w-[148px] md:h-[30px] md:w-[100px] md:text-[10px]'
					: size === 'small'
					? 'h-10 w-[140px] md:h-[30px] md:w-20 md:text-xs'
					: size === 'large'
					? 'h-[58px] w-[232px] md:h-8 md:w-[132px] md:text-[11px]'
					: ''
			}`}
			onClick={onClick}
		>
			<span className="relative">
				{children}
				<div
					className={`${
						isActive && (size === 'medium' || size === 'large') ? 'block' : 'hidden'
					} animate-scale absolute -bottom-px left-1/2 h-0.5 -translate-x-1/2 bg-red md:h-px`}
				/>
			</span>
		</button>
	) : (
		<>{children}</>
	)
}

export default Button
