import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useEthers, shortenAddress, BSC } from '@usedapp/core'

import WalletConnectModal from 'components/Modal/WalletConnectModal'
import { ReactComponent as WalletIcon } from '@material-icons/svg/svg/account_balance_wallet/outline.svg'
import useWalletConnectModal from 'hooks/useWalletConnectModal'

interface Props {
	variant?: string
}

const WalletConnectButton = (props: Props) => {
	const { variant = 'normal' } = props
	const { isOpen, setIsOpen } = useWalletConnectModal()
	const { t } = useTranslation()
	const { chainId, switchNetwork, deactivate } = useEthers()
	const { account } = useEthers()
	// const account = '0xeFab2b7888D831Ac18b0598Dfd44101F36cB7C81'

	const handleClick = useCallback(() => {
		if (!account) {
			setIsOpen(true)
		} else if (chainId !== BSC.chainId) {
			switchNetwork(BSC.chainId)
		} else {
			deactivate()
		}
	}, [chainId, switchNetwork, account, deactivate, setIsOpen])

	return (
		<>
			<WalletConnectModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
			<button
				className={`flex items-center justify-center rounded-md border-2 border-primary text-primary ${
					variant !== 'small' ? ' h-12 w-48 gap-2' : 'h-8 w-32 gap-1 text-[10px]'
				}`}
				onClick={handleClick}
			>
				<WalletIcon className={`fill-primary ${variant !== 'small' ? 'w-6' : 'w-4'}`} />
				{account
					? chainId === BSC.chainId
						? shortenAddress(account)
						: t('Switch Network')
					: t('Connect Wallet')}
			</button>
		</>
	)
}

export default WalletConnectButton
