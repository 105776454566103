import { useState } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { useEthers } from '@usedapp/core'
// import { isMobile } from 'react-device-detect'

import useWindowSize from 'hooks/useWindowSize'
import ModalWrapper from 'components/Wrapper/ModalWrapper'
import { ReactComponent as MetamaskIcon } from 'icons/metamask.svg'
import { ReactComponent as CoinbaseIcon } from 'icons/coinbase.svg'
import { ReactComponent as WalletconnectIcon } from 'icons/walletconnect.svg'
import { ReactComponent as CloseIcon } from '@material-icons/svg/svg/close/outline.svg'

interface Props {
	isOpen: boolean
	closeModal: () => void
}

const buttons = [
	{
		title: 'Metamask',
		name: 'metamask',
		component: <MetamaskIcon className="h-8 w-8 sm:h-6 sm:w-6" />,
		showOnMobile: false,
	},
	{
		title: 'Coinbase',
		name: 'coinbase',
		component: <CoinbaseIcon className="h-8 w-8 sm:h-6 sm:w-6" />,
		showOnMobile: false,
	},
	{
		title: 'WalletConnect',
		name: 'walletConnect',
		component: <WalletconnectIcon className="h-8 w-8 sm:h-6 sm:w-6" />,
		showOnMobile: true,
	},
]

const WalletConnectModal = (props: Props) => {
	const { isOpen, closeModal } = props
	const [wallet, setWallet] = useState<string>('')
	const { t } = useTranslation()
	const { activateBrowserWallet } = useEthers()
	const { width } = useWindowSize()

	const handleSelectWallet = (title: string) => {
		if (title === wallet) {
			setWallet('')
		} else {
			setWallet(title)
		}
	}

	const connect = () => {
		activateBrowserWallet({ type: wallet })
		closeModal()
	}

	return (
		<Modal ariaHideApp={false} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={closeModal}>
			<ModalWrapper>
				<CloseIcon
					className="absolute top-8 right-6 h-8 w-8 cursor-pointer fill-primary sm:right-3 sm:h-6 sm:w-6"
					onClick={closeModal}
				/>
				<h1 className="modal-header">{t('Connect to a Wallet')}</h1>
				<div className="my-10 grid gap-10 sm:gap-6">
					{buttons
						.filter((button) => (width ?? 10000) >= 768 || button.showOnMobile)
						.map((button) => (
							<button
								className={`flex items-center gap-6 rounded-[10px] border border-[#FFFFFF26] py-2.5 pl-4 text-primary sm:gap-3 sm:py-1.5 sm:text-[10px] ${
									wallet === button.name
										? 'bg-[#FFFFFF26]'
										: 'from-[#23009619] to-[#5D535321] backdrop-brightness-110 bg-gradient-95'
								}`}
								key={button.name}
								onClick={() => handleSelectWallet(button.name)}
							>
								{button.component}
								{button.title}
							</button>
						))}
				</div>
				<button className="btn mx-auto" disabled={wallet === ''} onClick={connect}>
					{t('Connect')}
				</button>
			</ModalWrapper>
		</Modal>
	)
}

export default WalletConnectModal
